.root {
  background-color: var(--store-background-color);
}

.root > nav > a:link {
  display: inline-block;
  margin-right: 8px;
}

.root h1 {
  margin-bottom: 0;
}

.root h2 {
  margin-bottom: 0;
}