.root {
  & > section {
    display: grid;
    grid-template-columns: 200px 1fr 300px;
    border: 1px solid black;

    pre {
      font-size: 0.8rem;
      flex: 1;
      margin: 0;
      border-radius: 0;
    }

    form {
      text-align: center;
      background-color: whitesmoke;
    }

    ul {
      padding-left: 0;
      list-style-type: none;
      max-height: 120px;
      overflow-y: scroll;
      li {
        font-size: 9px;
        user-select: none;
        cursor: pointer;
      }
    }
  }
}

.apiNavLinks {
  padding: 8px;
  border: 1px solid black;
  display: grid;
  
}

.albumsList {
  display: grid;
  grid-template-columns: repeat(auto-fit, 268px);
}
