//styles for bootstrap 5.0.2 slider
.sale_code {
    position: absolute;
    top: 100px;
    right: 30px;
    z-index: 9;
    color: #fff !important;
    font-size: 20px !important;
}

@media (max-width: 1280px) {
    .sale_code {
        +.research-paper-slider {
            .caption-wrap {
                min-height: 620px !important;
                padding-top: 175px !important;
            }
        }
    }
}

@media (max-width: 768px) {
    .sale_code {
        text-align: center;
        width: 100%;
        right: 50%;
        transform: translateX(50%);
        +.research-paper-slider {
            .caption-wrap {
                padding-top: 190px !important;
            }
        }
    }
}

@media (max-width: 600px) {
    .sale_code {
        width: 280px;
        +.research-paper-slider {
            .caption-wrap {
                justify-content: flex-start;
            }
        }
    }
}

.research-paper-slider {
    .carousel-inner {
        position: relative !important;
        width: 100% !important;
        overflow: hidden !important;

        @media (max-width: 600px) {
            .caption-wrap.caption-top-align {
                padding-top: 40px;
            }
        }

        @media (max-width: 480px) {
            a.responsive-image-wrapper {
                width: unset;
                max-width: 300px;
            }

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 0;
                width: 80px;
                height: 100%;
                pointer-events: none;
                z-index: 2;
            }

            &::before {
                left: 0;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
            }

            &::after {
                right: 0;
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
            }
        }
    }

    .carousel-inner::after {
        display: block !important;
        clear: both !important;
        content: "" !important;
    }

    .carousel-item {
        position: relative !important;
        float: left !important;
        width: 100% !important;
        display: none !important;
        margin-right: -100% !important;
        -webkit-backface-visibility: hidden !important;
        backface-visibility: hidden !important;
        transition: transform 0.6s ease-in-out !important;
    }

    @media (prefers-reduced-motion: reduce) {
        .carousel-item {
            transition: none !important;
        }
    }

    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
        display: block !important;
    }

    &.carousel-fade .carousel-item {
        opacity: 0 !important;
        transition-property: opacity !important;
        transform: none !important;
    }

    &.carousel-fade .carousel-item.active,
    &.carousel-fade .carousel-item-next.carousel-item-start,
    &.carousel-fade .carousel-item-prev.carousel-item-end {
        z-index: 1 !important;
        opacity: 1 !important;
    }

    &.carousel-fade .active.carousel-item-start,
    &.carousel-fade .active.carousel-item-end {
        z-index: 0 !important;
        opacity: 0 !important;
        transition: opacity 0s 0.6s !important;
    }

    @media (prefers-reduced-motion: reduce) {

        &.carousel-fade .active.carousel-item-start,
        &.carousel-fade .active.carousel-item-end {
            transition: none !important;
        }
    }
}