/* Paypal related */
#add_payment_method #payment div.payment_box #wc-pal_pro_payflow-cc-form input.input-text,
#add_payment_method #payment div.payment_box #wc-pal_pro_payflow-cc-form textarea,
#add_payment_method #payment div.payment_box #wc-paypal_pro_payflow-cc-form input.input-text,
#add_payment_method #payment div.payment_box #wc-paypal_pro_payflow-cc-form textarea,
.woocommerce-cart #payment div.payment_box #wc-pal_pro_payflow-cc-form input.input-text,
.woocommerce-cart #payment div.payment_box #wc-pal_pro_payflow-cc-form textarea,
.woocommerce-cart #payment div.payment_box #wc-paypal_pro_payflow-cc-form input.input-text,
.woocommerce-cart #payment div.payment_box #wc-paypal_pro_payflow-cc-form textarea,
.woocommerce-checkout #payment div.payment_box #wc-pal_pro_payflow-cc-form input.input-text,
.woocommerce-checkout #payment div.payment_box #wc-pal_pro_payflow-cc-form textarea,
.woocommerce-checkout #payment div.payment_box #wc-paypal_pro_payflow-cc-form input.input-text,
.woocommerce-checkout #payment div.payment_box #wc-paypal_pro_payflow-cc-form textarea {
    position: relative;
    opacity: 1
}

#add_payment_method #payment div.payment_box #wc-pal_pro_payflow-cc-form .wc-credit-card-form-card-cvc.visa,
#add_payment_method #payment div.payment_box #wc-pal_pro_payflow-cc-form .wc-credit-card-form-card-expiry.visa,
#add_payment_method #payment div.payment_box #wc-pal_pro_payflow-cc-form .wc-credit-card-form-card-number.visa,
#add_payment_method #payment div.payment_box #wc-paypal_pro_payflow-cc-form .wc-credit-card-form-card-cvc.visa,
#add_payment_method #payment div.payment_box #wc-paypal_pro_payflow-cc-form .wc-credit-card-form-card-expiry.visa,
#add_payment_method #payment div.payment_box #wc-paypal_pro_payflow-cc-form .wc-credit-card-form-card-number.visa,
.woocommerce-cart #payment div.payment_box #wc-pal_pro_payflow-cc-form .wc-credit-card-form-card-cvc.visa,
.woocommerce-cart #payment div.payment_box #wc-pal_pro_payflow-cc-form .wc-credit-card-form-card-expiry.visa,
.woocommerce-cart #payment div.payment_box #wc-pal_pro_payflow-cc-form .wc-credit-card-form-card-number.visa,
.woocommerce-cart #payment div.payment_box #wc-paypal_pro_payflow-cc-form .wc-credit-card-form-card-cvc.visa,
.woocommerce-cart #payment div.payment_box #wc-paypal_pro_payflow-cc-form .wc-credit-card-form-card-expiry.visa,
.woocommerce-cart #payment div.payment_box #wc-paypal_pro_payflow-cc-form .wc-credit-card-form-card-number.visa,
.woocommerce-checkout #payment div.payment_box #wc-pal_pro_payflow-cc-form .wc-credit-card-form-card-cvc.visa,
.woocommerce-checkout #payment div.payment_box #wc-pal_pro_payflow-cc-form .wc-credit-card-form-card-expiry.visa,
.woocommerce-checkout #payment div.payment_box #wc-pal_pro_payflow-cc-form .wc-credit-card-form-card-number.visa,
.woocommerce-checkout #payment div.payment_box #wc-paypal_pro_payflow-cc-form .wc-credit-card-form-card-cvc.visa,
.woocommerce-checkout #payment div.payment_box #wc-paypal_pro_payflow-cc-form .wc-credit-card-form-card-expiry.visa,
.woocommerce-checkout #payment div.payment_box #wc-paypal_pro_payflow-cc-form .wc-credit-card-form-card-number.visa {
    background-image: none
}

.woocommerce-page:not(.archive) .page-wrapper form #wc-pal_pro_payflow-cc-form label,
.woocommerce-page:not(.archive) .page-wrapper form #wc-paypal_pro_payflow-cc-form label {
    padding-left: 0
}

.woocommerce-page:not(.archive) .page-wrapper form #wc-pal_pro_payflow-cc-form label:before,
.woocommerce-page:not(.archive) .page-wrapper form #wc-paypal_pro_payflow-cc-form label:before {
    content: none
}

/* Course accordion related */
.course-accordion {
    counter-reset: accordion-counter
}

.course-accordion .panel {
    position: relative;
    counter-reset: lessons-counter
}

.course-accordion .panel .panel-collapse {
    margin-left: 75px
}

.course-accordion .panel .panel-heading {
    margin-left: 75px;
    margin-bottom: 17px;
    border-radius: 6px;
    background: 0 0 !important
}

.course-accordion .panel .panel-title {
    position: relative;
    z-index: 2
}

.course-accordion .panel .panel-title>a {
    position: relative;
    padding: 16px 28px;
    font-size: 21px;
    line-height: 48px;
    font-weight: 500;
    border-radius: 6px;
    color: rgba(54, 76, 105, .5) !important;
    background-color: rgba(255, 255, 255, .5) !important;
    z-index: 2
}

.course-accordion .panel .panel-title>a[aria-expanded=true] {
    color: #364c69 !important;
    background-color: rgba(184, 139, 239, .1) !important
}

.course-accordion .panel .panel-title>a:before {
    counter-increment: accordion-counter;
    content: counter(accordion-counter);
    display: inline-block;
    position: absolute;
    top: 50%;
    left: -59px;
    width: 36px;
    height: 36px;
    font-size: 16px;
    line-height: 37px;
    font-weight: 400;
    margin-top: -18px;
    border-radius: 36px;
    text-align: center;
    color: #364c69;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2)
}

.course-accordion .panel .panel-title>a:after {
    display: none
}

.course-accordion .panel .panel-title:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: -43px;
    width: 4px;
    height: 36px;
    margin-top: -24px;
    border-top: 6px solid #fff;
    border-bottom: 6px solid #fff;
    z-index: 3;
    box-sizing: content-box;
    pointer-events: none
}

.course-accordion .panel .panel-body {
    padding: 10px 0 40px;
    background-color: transparent !important;
    color: rgba(54, 76, 105, .8) !important;
    font-size: 16px;
    line-height: 20px
}

.course-accordion .panel .panel-body .btn.btn-secondary.btn-md {
    color: #7235fd
}

.course-accordion .panel .panel-body p {
    margin-bottom: 15px
}

.course-accordion .panel .panel-body hr {
    border-top: 1px solid #364c69;
    opacity: .4
}

.course-accordion .panel:after {
    content: '';
    position: absolute;
    top: 50px;
    left: 32px;
    width: 4px;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
    transition: background-color .3s
}

.course-accordion .panel:first-child .panel-title:before {
    border-top-color: transparent
}

.course-accordion .panel:last-child:after {
    display: none
}

.course-accordion .panel:last-child .panel-title:before {
    border-bottom-color: transparent
}

.course-accordion .panel.blocked-panel,
.course-accordion .panel.panel-can-be-opened {
    cursor: not-allowed
}

.course-accordion .panel.blocked-panel .panel-collapse,
.course-accordion .panel.blocked-panel .panel-heading,
.course-accordion .panel.panel-can-be-opened .panel-collapse,
.course-accordion .panel.panel-can-be-opened .panel-heading {
    pointer-events: none
}

.course-accordion .panel.panel-opened .panel-title>a:before {
    background-color: #b171fe;
    color: #fff
}

.course-accordion .panel.panel-opened .panel-title:before {
    opacity: 0
}

.course-accordion .panel.panel-opened:after {
    background-color: #b171fe
}

.course-accordion .panel.panel-last-opened:last-child .panel-title>a:before {
    background-color: #b171fe;
    color: #fff
}

.course-accordion .panel.panel-last-opened:last-child .panel-title:before {
    opacity: 0
}

.course-accordion .panel.panel-last-opened:after {
    background-color: #fff
}

/* Course Lesson related */
.hemi-lesson {
  position: relative;
  padding-left: 26px;
  font-weight: 400;
  opacity: 1;
  z-index: 10;
}

.hemi-lesson .hemi-lesson-button-container {
  display: block;
}

.hemi-lesson .hemi-lesson-index {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 45px;
  width: 1em;
  line-height: 1em;
  margin-top: -0.5em;
  font-weight: 500;
  color: #364c69;
}

.hemi-lesson .hemi-lesson-index:after {
  counter-increment: lessons-counter;
  content: counter(lessons-counter) ".";
}

.hemi-lesson .hemi-lesson-media-container {
  position: relative;
  width: 157px;
  height: 90px;
  background-color: #dcdcdc;
  margin-right: 16px;
  float: left;
  text-align: left;
}

.hemi-lesson .hemi-lesson-media-container .hemi-lesson-media-wrapper {
  display: inline-block;
  position: relative;
  max-width: 100%;
}

.hemi-lesson .hemi-lesson-media-container .hemi-lesson-media-wrapper > a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.hemi-lesson .hemi-lesson-media-container .hemi-lesson-media-wrapper > a svg {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -27px;
  margin-left: -27px;
}

.hemi-lesson
  .hemi-lesson-media-container
  .hemi-lesson-media-wrapper
  .hemi-lesson-audio
  .hemi-lesson-media-video-icon {
  display: none;
}

.hemi-lesson
  .hemi-lesson-media-container
  .hemi-lesson-media-wrapper.media-is-playing
  .hemi-lesson-player-container {
  display: block;
}

.hemi-lesson .hemi-lesson-media-container.has_thumb {
  height: auto;
  background-color: transparent;
}

.hemi-lesson .hemi-lesson-media-container.has_thumb img {
  max-height: 115px;
}

.hemi-lesson
  .hemi-lesson-media-container:not(.has_thumb)
  .hemi-lesson-media-wrapper {
  width: 100%;
  height: 100%;
}

.hemi-lesson .hemi-lesson-title {
  margin-bottom: 8px;
  color: #446c93;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.hemi-lesson .hemi-lesson-audio-button {
  margin-top: -10px;
}

.hemi-lesson .hemi-lesson-audio-button svg {
  margin-right: 9px;
}

.hemi-lesson .hemi-lesson-audio-button svg path {
  fill: #ac74fe;
}

.hemi-lesson .hemi-lesson-audio-button .btn.btn-link:focus span svg path,
.hemi-lesson .hemi-lesson-audio-button .btn.btn-link:hover span svg path {
  fill: #7170cb;
}

.hemi-lesson .hemi-lesson-content-container {
  padding-top: 0;
}

.hemi-lesson .hemi-lesson-content-container:after {
  content: "";
  clear: both;
  display: table;
}

.hemi-lesson .hemi-lesson-button-container {
  padding-top: 18px;
  display: none;
}

.hemi-lesson .hemi-lesson-media-info .hemi-lesson-info-label {
  color: rgba(54, 76, 105, 0.7);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.hemi-lesson .hemi-lesson-media-info .hemi-lesson-info-value {
  color: #364c69;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.hemi-lesson .hemi-lesson-media-info .hemi-lesson-media-author,
.hemi-lesson .hemi-lesson-media-info .hemi-lesson-media-length {
  float: left;
}

.hemi-lesson .hemi-lesson-media-info .hemi-lesson-media-author {
  min-width: 110px;
  margin-right: 24px;
}

.hemi-lesson .hemi-lesson-media-info:after {
  content: "";
  display: table;
  clear: both;
}

.hemi-lesson.opened-lesson {
  opacity: 1;
  pointer-events: auto;
}

.hemi-lesson + .hemi-lesson {
  margin-top: 30px;
}

.hemi-lesson:after {
  content: "";
  clear: both;
  display: table;
}

.hemi-lesson-html-mpf .mfp-content {
  pointer-events: none;
}

.hemi-lesson-html-mpf .mfp-content .ff-lightbox-embed-inner {
  pointer-events: auto;
}

.hemi-lesson-lightbox .mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.hemi-lesson-lightbox .mfp-iframe-holder .ff-lightbox-embed-inner {
  line-height: 0;
  width: 100%;
  max-width: 900px;
  max-height: 95vh;
  padding: 0;
}

.hemi-lesson-lightbox .mfp-iframe-holder .mfp-close {
  right: auto;
  left: calc(50% + 415px);
  top: 0;
  pointer-events: auto;
}

.hemi-lesson-lightbox .mejs-offscreen {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal;
}

.hemi-lesson-lightbox .mejs-container {
  background: #000;
  font-family: Helvetica, Arial, serif;
  position: relative;
  text-align: left;
  text-indent: 0;
  vertical-align: top;
  margin-bottom: 0;
  overflow: hidden;
}

.hemi-lesson-lightbox .mejs-container,
.hemi-lesson-lightbox .mejs-container * {
  box-sizing: border-box;
}

.hemi-lesson-lightbox .mejs-container video::-webkit-media-controls,
.hemi-lesson-lightbox .mejs-container video::-webkit-media-controls-panel,
.hemi-lesson-lightbox
  .mejs-container
  video::-webkit-media-controls-panel-container,
.hemi-lesson-lightbox
  .mejs-container
  video::-webkit-media-controls-start-playback-button {
  -webkit-appearance: none;
  display: none !important;
}

.hemi-lesson-lightbox .mejs-fill-container,
.hemi-lesson-lightbox .mejs-fill-container .mejs-container {
  height: 100%;
  width: 100%;
}

.hemi-lesson-lightbox .mejs-fill-container {
  background: 0 0;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.hemi-lesson-lightbox .mejs-container:focus {
  outline: 0;
}

.hemi-lesson-lightbox .mejs-iframe-overlay {
  height: 100%;
  position: absolute;
  width: 100%;
}

.hemi-lesson-lightbox .mejs-embed,
.hemi-lesson-lightbox .mejs-embed body {
  background: #000;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.hemi-lesson-lightbox .mejs-fullscreen {
  overflow: hidden !important;
}

.hemi-lesson-lightbox .mejs-container-fullscreen {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}

.hemi-lesson-lightbox .mejs-container-fullscreen .mejs-mediaelement,
.hemi-lesson-lightbox .mejs-container-fullscreen video {
  height: 100% !important;
  width: 100% !important;
}

.hemi-lesson-lightbox .mejs-background,
.hemi-lesson-lightbox .mejs-mediaelement {
  left: 0;
  position: absolute;
  top: 0;
}

.hemi-lesson-lightbox .mejs-mediaelement {
  height: 100%;
  width: 100%;
  z-index: 0;
}

.hemi-lesson-lightbox .mejs-poster {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

.hemi-lesson-lightbox :root .mejs-poster-img {
  display: none;
}

.hemi-lesson-lightbox .mejs-poster-img {
  border: 0;
  padding: 0;
}

.hemi-lesson-lightbox .mejs-overlay {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
}

.hemi-lesson-lightbox .mejs-layer {
  z-index: 1;
}

.hemi-lesson-lightbox .mejs-overlay-play {
  cursor: pointer;
}

.hemi-lesson-lightbox .mejs-overlay-button {
  background: url(./images/player-play-white.svg) no-repeat;
  background-size: 60px;
  height: 60px;
  width: 60px;
  opacity: 0.65;
  transition: all 0.3s ease-in;
}

.hemi-lesson-lightbox .mejs-overlay:hover > .mejs-overlay-button {
  opacity: 1;
  background-position: center;
}

@keyframes a {
  to {
    transform: rotate(1turn);
  }
}

.hemi-lesson-lightbox .mejs-controls {
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 0 10px;
  position: absolute;
  width: 100%;
  z-index: 3 !important;
}

.hemi-lesson-lightbox .mejs-controls:not([style*="display: none"]) {
  background: rgba(255, 0, 0, 0.7);
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.35));
}

.hemi-lesson-lightbox .mejs-button,
.hemi-lesson-lightbox .mejs-time,
.hemi-lesson-lightbox .mejs-time-rail {
  font-size: 10px;
  height: 40px;
  line-height: 10px;
  margin: 0;
  width: 32px;
}

.hemi-lesson-lightbox .mejs-button button {
  background: transparent url(./images/player-play-white.svg) no-repeat;
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  margin: 10px 6px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-decoration: none;
  width: 20px;
  outline: 0;
}

.hemi-lesson-lightbox .mejs-time {
  box-sizing: content-box;
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  height: 24px;
  overflow: hidden;
  padding: 16px 6px 0;
  text-align: center;
  width: auto;
}

.hemi-lesson-lightbox .mejs-play > button {
  background: url(./images/player-play-white.svg) no-repeat;
  background-size: 20px;
}

.hemi-lesson-lightbox .mejs-pause > button {
  background: url(./images/player-pause-white.svg) no-repeat;
  background-size: 20px;
}

.hemi-lesson-lightbox .mejs-replay > button {
  background: url(./images/player-play-white.svg) no-repeat;
  background-size: 20px;
}

.hemi-lesson-lightbox .mejs-time-rail {
  direction: ltr;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 40px;
  margin: 0 10px;
  padding-top: 10px;
  position: relative;
}

.hemi-lesson-lightbox .mejs-time-buffering,
.hemi-lesson-lightbox .mejs-time-current,
.hemi-lesson-lightbox .mejs-time-float,
.hemi-lesson-lightbox .mejs-time-float-corner,
.hemi-lesson-lightbox .mejs-time-float-current,
.hemi-lesson-lightbox .mejs-time-hovered,
.hemi-lesson-lightbox .mejs-time-loaded,
.hemi-lesson-lightbox .mejs-time-marker,
.hemi-lesson-lightbox .mejs-time-total {
  border-radius: 2px;
  cursor: pointer;
  display: block;
  height: 10px;
  position: absolute;
}

.hemi-lesson-lightbox .mejs-time-total {
  background: rgba(255, 255, 255, 0.3);
  margin: 5px 0 0;
  width: 100%;
}

.hemi-lesson-lightbox .mejs-time-buffering {
  animation: b 2s linear infinite;
  background: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.4) 25%,
    transparent 0,
    transparent 50%,
    rgba(255, 255, 255, 0.4) 0,
    rgba(255, 255, 255, 0.4) 75%,
    transparent 0,
    transparent
  );
  background-size: 15px 15px;
  width: 100%;
}

@keyframes b {
  0% {
    background-position: 0 0;
  }

  to {
    background-position: 30px 0;
  }
}

.hemi-lesson-lightbox .mejs-time-loaded {
  background: rgba(255, 255, 255, 0.3);
}

.hemi-lesson-lightbox .mejs-time-current,
.hemi-lesson-lightbox .mejs-time-handle-content {
  background: rgba(255, 255, 255, 0.9);
}

.hemi-lesson-lightbox .mejs-time-hovered {
  background: rgba(255, 255, 255, 0.5);
  z-index: 10;
}

.hemi-lesson-lightbox .mejs-time-hovered.negative {
  background: rgba(0, 0, 0, 0.2);
}

.hemi-lesson-lightbox .mejs-time-buffering,
.hemi-lesson-lightbox .mejs-time-current,
.hemi-lesson-lightbox .mejs-time-hovered,
.hemi-lesson-lightbox .mejs-time-loaded {
  left: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  transition: all 0.15s ease-in;
  width: 100%;
}

.hemi-lesson-lightbox .mejs-time-buffering {
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.hemi-lesson-lightbox .mejs-time-hovered {
  transition: height 0.1s cubic-bezier(0.44, 0, 1, 1);
}

.hemi-lesson-lightbox .mejs-time-hovered.no-hover {
  -ms-transform: scaleX(0) !important;
  transform: scaleX(0) !important;
}

.hemi-lesson-lightbox .mejs-time-handle,
.hemi-lesson-lightbox .mejs-time-handle-content {
  border: 4px solid transparent;
  cursor: pointer;
  left: 0;
  position: absolute;
  -ms-transform: translateX(0);
  transform: translateX(0);
  z-index: 11;
}

.hemi-lesson-lightbox .mejs-time-handle-content {
  border: 4px solid rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  height: 10px;
  left: -7px;
  top: -4px;
  -ms-transform: scale(0);
  transform: scale(0);
  width: 10px;
}

.hemi-lesson-lightbox .mejs-time-rail .mejs-time-handle-content:active,
.hemi-lesson-lightbox .mejs-time-rail .mejs-time-handle-content:focus,
.hemi-lesson-lightbox .mejs-time-rail:hover .mejs-time-handle-content {
  -ms-transform: scale(1);
  transform: scale(1);
}

.hemi-lesson-lightbox .mejs-time-float {
  background: #eee;
  border: 1px solid #333;
  bottom: 100%;
  color: #111;
  display: none;
  height: 17px;
  margin-bottom: 9px;
  position: absolute;
  text-align: center;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 36px;
}

.hemi-lesson-lightbox .mejs-time-float-current {
  display: block;
  left: 0;
  margin: 2px;
  text-align: center;
  width: 30px;
}

.hemi-lesson-lightbox .mejs-time-float-corner {
  border: 5px solid #eee;
  border-color: #eee transparent transparent;
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  line-height: 0;
  position: absolute;
  top: 100%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0;
}

.hemi-lesson-lightbox .mejs-long-video .mejs-time-float {
  margin-left: -23px;
  width: 64px;
}

.hemi-lesson-lightbox .mejs-long-video .mejs-time-float-current {
  width: 60px;
}

.hemi-lesson-lightbox .mejs-broadcast {
  color: #fff;
  height: 10px;
  position: absolute;
  top: 15px;
  width: 100%;
}

.hemi-lesson-lightbox .mejs-fullscreen-button > button {
  background: transparent url(./images/player-fullscreen-white.svg) no-repeat;
  background-size: 18px;
}

.hemi-lesson-lightbox .mejs-unfullscreen > button {
  background: transparent url(./images/player-minimize-white.svg) no-repeat;
  background-size: 18px;
}

.hemi-lesson-lightbox .mejs-mute > button {
  background: transparent url(./images/player-volume-white.svg) no-repeat;
  background-size: 18px;
}

.hemi-lesson-lightbox .mejs-unmute > button {
  background: transparent url(./images/player-volume-mute-white.svg) no-repeat;
  background-size: 18px;
}

.hemi-lesson-lightbox .mejs-volume-button {
  position: relative;
}

.hemi-lesson-lightbox .mejs-volume-button > .mejs-volume-slider {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: rgba(50, 50, 50, 0.7);
  border-radius: 0;
  bottom: 100%;
  display: none;
  height: 115px;
  left: 50%;
  margin: 0;
  position: absolute;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 25px;
  z-index: 1;
}

.hemi-lesson-lightbox .mejs-volume-button:hover {
  border-radius: 0 0 4px 4px;
}

.hemi-lesson-lightbox .mejs-volume-total {
  background: rgba(255, 255, 255, 0.5);
  height: 100px;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 8px;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 2px;
}

.hemi-lesson-lightbox .mejs-volume-current {
  left: 0;
  margin: 0;
  width: 100%;
}

.hemi-lesson-lightbox .mejs-volume-current,
.hemi-lesson-lightbox .mejs-volume-handle {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
}

.hemi-lesson-lightbox .mejs-volume-handle {
  border-radius: 1px;
  cursor: ns-resize;
  height: 6px;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 16px;
}

.hemi-lesson-lightbox .mejs-horizontal-volume-slider {
  display: block;
  height: 36px;
  position: relative;
  width: 56px;
}

.hemi-lesson-lightbox .mejs-horizontal-volume-total {
  background: rgba(50, 50, 50, 0.8);
  height: 8px;
  top: 16px;
  width: 50px;
}

.hemi-lesson-lightbox .mejs-horizontal-volume-current,
.hemi-lesson-lightbox .mejs-horizontal-volume-total {
  border-radius: 2px;
  font-size: 1px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
}

.hemi-lesson-lightbox .mejs-horizontal-volume-current {
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  top: 0;
  width: 100%;
}

.hemi-lesson-lightbox .mejs-horizontal-volume-handle {
  display: none;
}

.hemi-lesson-lightbox .mejs-captions-button,
.hemi-lesson-lightbox .mejs-chapters-button {
  position: relative;
}

.hemi-lesson-lightbox .mejs-captions-button > button {
  background-position: -140px 0;
}

.hemi-lesson-lightbox .mejs-chapters-button > button {
  background-position: -180px 0;
}

.hemi-lesson-lightbox .mejs-captions-button > .mejs-captions-selector,
.hemi-lesson-lightbox .mejs-chapters-button > .mejs-chapters-selector {
  background: rgba(50, 50, 50, 0.7);
  border: 1px solid transparent;
  border-radius: 0;
  bottom: 100%;
  margin-right: -43px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 50%;
  visibility: visible;
  width: 86px;
}

.hemi-lesson-lightbox .mejs-chapters-button > .mejs-chapters-selector {
  margin-right: -55px;
  width: 110px;
}

.hemi-lesson-lightbox .mejs-captions-selector-list,
.hemi-lesson-lightbox .mejs-chapters-selector-list {
  list-style-type: none !important;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.hemi-lesson-lightbox .mejs-captions-selector-list-item,
.hemi-lesson-lightbox .mejs-chapters-selector-list-item {
  color: #fff;
  cursor: pointer;
  display: block;
  list-style-type: none !important;
  margin: 0 0 6px;
  overflow: hidden;
  padding: 0;
}

.hemi-lesson-lightbox .mejs-captions-selector-list-item:hover,
.hemi-lesson-lightbox .mejs-chapters-selector-list-item:hover {
  background-color: #c8c8c8 !important;
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.hemi-lesson-lightbox .mejs-captions-selector-input,
.hemi-lesson-lightbox .mejs-chapters-selector-input {
  clear: both;
  float: left;
  left: -1000px;
  margin: 3px 3px 0 5px;
  position: absolute;
}

.hemi-lesson-lightbox .mejs-captions-selector-label,
.hemi-lesson-lightbox .mejs-chapters-selector-label {
  cursor: pointer;
  float: left;
  font-size: 10px;
  line-height: 15px;
  padding: 4px 10px 0;
  width: 100%;
}

.hemi-lesson-lightbox .mejs-captions-selected,
.hemi-lesson-lightbox .mejs-chapters-selected {
  color: #21f8f8;
}

.hemi-lesson-lightbox .mejs-captions-translations {
  font-size: 10px;
  margin: 0 0 5px;
}

.hemi-lesson-lightbox .mejs-captions-layer {
  bottom: 0;
  color: #fff;
  font-size: 16px;
  left: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
}

.hemi-lesson-lightbox .mejs-captions-layer a {
  color: #fff;
  text-decoration: underline;
}

.hemi-lesson-lightbox .mejs-captions-layer[lang="ar"] {
  font-size: 20px;
  font-weight: 400;
}

.hemi-lesson-lightbox .mejs-captions-position {
  bottom: 15px;
  left: 0;
  position: absolute;
  width: 100%;
}

.hemi-lesson-lightbox .mejs-captions-position-hover {
  bottom: 35px;
}

.hemi-lesson-lightbox .mejs-captions-text,
.hemi-lesson-lightbox .mejs-captions-text * {
  background: rgba(20, 20, 20, 0.5);
  box-shadow: 5px 0 0 rgba(20, 20, 20, 0.5), -5px 0 0 rgba(20, 20, 20, 0.5);
  padding: 0;
  white-space: pre-wrap;
}

.hemi-lesson-lightbox
  .mejs-container.mejs-hide-cues
  video::-webkit-media-text-track-container {
  display: none;
}

.hemi-lesson-lightbox .mejs-overlay-error {
  position: relative;
}

.hemi-lesson-lightbox .mejs-overlay-error > img {
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}

.hemi-lesson-lightbox .mejs-cannotplay,
.hemi-lesson-lightbox .mejs-cannotplay a {
  color: #fff;
  font-size: 0.8em;
}

.hemi-lesson-lightbox .mejs-cannotplay {
  position: relative;
}

.hemi-lesson-lightbox .mejs-cannotplay a,
.hemi-lesson-lightbox .mejs-cannotplay p {
  display: inline-block;
  padding: 0 15px;
  width: 100%;
}

.hemi-lesson-player-container .wcmp-player {
  width: 100% !important;
}

.hemi-lesson-player-container .wcmp-player.mejs-ted:not(.track),
.hemi-lesson-player-container .wcmp-player.mejs-wmp:not(.track) {
  min-height: 65px !important;
}

.hemi-lesson-player-container .wcmp-player.mejs-container {
  background: 0 0 !important;
}

.hemi-lesson-player-container .wcmp-player.track {
  width: 25px !important;
  position: relative;
  float: left;
  margin-right: 5px;
  overflow: hidden;
}

.hemi-lesson-player-container .wcmp-player.track.mejs-ted {
  width: 49px !important;
  height: 28px !important;
}

.hemi-lesson-player-container .wcmp-player.track.mejs-ted .mejs-controls {
  height: 28px !important;
  background: 0 0 !important;
}

.hemi-lesson-player-container
  .wcmp-player.track.mejs-ted
  .mejs-playpause-button,
.hemi-lesson-player-container
  .wcmp-player.track.mejs-wmp
  .mejs-playpause-button {
  top: 0 !important;
  left: 0 !important;
  margin: 0 !important;
}

.hemi-lesson-player-container .wcmp-player.track.mejs-wmp {
  width: 40px !important;
  height: 40px !important;
}

.hemi-lesson-player-container .wcmp-player.track.mejs-wmp .mejs-controls {
  height: 40px !important;
  width: 40px !important;
  background: 0 0 !important;
}

.hemi-lesson-player-container .wcmp-player-list {
  width: 100%;
  border: 0;
}

.hemi-lesson-player-container .wcmp-player-list td {
  border: 0;
}

.hemi-lesson-player-container .wcmp-player-list table {
  width: 100%;
}

.hemi-lesson-player-container .wcmp-player-list table td {
  border: 0;
}

.hemi-lesson-player-container .wcmp-odd-row td {
  background-color: #fafafa;
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
}

.hemi-lesson-player-container .wcmp-column-player-title {
  vertical-align: middle;
}

.hemi-lesson-player-container .wcmp-column-player-mejs-classic {
  width: 30px;
  min-height: 30px;
}

.hemi-lesson-player-container .wcmp-column-player-mejs-ted {
  width: 54px;
  min-height: 28px;
}

.hemi-lesson-player-container .wcmp-column-player-mejs-wmp {
  width: 45px;
  min-height: 40px;
}

.hemi-course-lesson-wrapper
  .wcmp-player-container
  .mejs-container
  .mejs-controls
  .mejs-time.mejs-currenttime-container {
  right: 450px;
}

@media (max-width: 992px) {
  .hemi-course-lesson-wrapper
    .wcmp-player-container
    .mejs-container
    .mejs-controls
    .mejs-playpause-button {
    right: 390px;
  }

  .hemi-course-lesson-wrapper
    .wcmp-player-container
    .mejs-container
    .mejs-controls
    .mejs-time.mejs-currenttime-container {
    right: 325px;
  }
}

@media (max-width: 768px) {
  .hemi-course-lesson-wrapper
    .wcmp-player-container
    .mejs-container
    .mejs-controls
    .mejs-playpause-button {
    right: 160px;
  }

  .hemi-course-lesson-wrapper
    .wcmp-player-container
    .mejs-container
    .mejs-controls
    .mejs-time.mejs-currenttime-container {
    right: 90px;
  }
}
