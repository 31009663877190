
// Placeholder

::-webkit-input-placeholder {
    opacity: .7;
    color: #364c69;
}
::-moz-placeholder {
    opacity: .7;
    color: #364c69;
}
:-moz-placeholder {
    opacity: .7;
    color: #364c69;
}
:-ms-input-placeholder {
    opacity: .7;
    color: #364c69;
}

//  Woocommerce form

.woocommerce-form-row {
    .woocommerce-password-strength {
        font-size: 15px;
        font-weight: 500;
        line-height: 2.67;
        position: relative;
        padding: 0;
        text-align: left;
        color: #364c69;
        background: none;
        &:before {
            position: absolute;
            top: -27px;
            left: calc(100% - 120px);
            display: block;
            width: 100px;
            height: 14px;
            content: '';
            border: solid 1px #e5e5e5;
            border-radius: 4px;
        }
        .auth-form & {
            font-size: 13px;
            font-weight: 400;
            &:before {
                top: -24px;
                left: calc(100% - 50px);
                width: 40px;
                height: 8px;
                border: 0;
            }
        }
        &.short {
            &:before {
                background: #e26a61;
                background:    -moz-linear-gradient(left, #e26a61 0%, #e26a61 25%, #eff1f3 25%, #eff1f3 100%);
                background: -webkit-linear-gradient(left, #e26a61 0%, #e26a61 25%, #eff1f3 25%, #eff1f3 100%);
                background:         linear-gradient(to right, #e26a61 0%, #e26a61 25%, #eff1f3 25%, #eff1f3 100%);
            }
        }
        &.bad {
            &:before {
                background: #f3ba5f;
                background:    -moz-linear-gradient(left, #f3ba5f 0%, #f3ba5f 50%, #eff1f3 50%, #eff1f3 100%);
                background: -webkit-linear-gradient(left, #f3ba5f 0%, #f3ba5f 50%, #eff1f3 50%, #eff1f3 100%);
                background:         linear-gradient(to right, #f3ba5f 0%, #f3ba5f 50%, #eff1f3 50%, #eff1f3 100%);
            }
        }
        &.good {
            &:before {
                background: #6dc26f;
                background:    -moz-linear-gradient(left, #6dc26f 0%, #6dc26f 75%, #eff1f3 75%, #eff1f3 100%);
                background: -webkit-linear-gradient(left, #6dc26f 0%, #6dc26f 75%, #eff1f3 75%, #eff1f3 100%);
                background:         linear-gradient(to right, #6dc26f 0%, #6dc26f 75%, #eff1f3 75%, #eff1f3 100%);
            }
            &:after {
                position: absolute;
                top: -27px;
                left: calc(100% - 140px);
                width: 15px;
                height: 10px;
                content: '';
                background: url('../images/Pass-med.svg');
                .auth-form & {
                    left: calc(100% - 70px);
                }
            }
        }
        &.strong {
            &:before {
                background: #5da95f;
            }
            &:after {
                position: absolute;
                top: -27px;
                left: calc(100% - 140px);
                width: 15px;
                height: 10px;
                content: '';
                background: url('../images/Pass-ok.svg');
                .auth-form & {
                    left: calc(100% - 70px);
                }
            }
        }
        & + small {
            font-size: 13px;
            line-height: 1.46;
            position: relative;
            padding: 15px;
            color: rgba(#364c69,.85);
            border-radius: 4px;
            background: #eff1f3;
            &:before {
                position: absolute;
                top: -16px;
                left: 28px;
                width: 0;
                height: 0;
                content: '';
                border: 8px solid transparent;
                border-bottom-color: #eff1f3;
            }
            .auth-form & {
                padding: 0;
                color: rgba(#364c69,.6);
                background: none;
                &:before {
                    display: none;
                }
            }
        }
    }
}

// Select 2

.select2-container {
    font-family: Rubik, sans-serif;
    .select2-selection {
        font-family: inherit;
        font-size: 16px!important;
        font-weight: 500;
        height: 40px!important;
        padding: 6px 10px!important;
        color: #364c69!important;
        border: solid 1px #e5e5e5!important;
        border-radius: 2px;
        background: #fff;
        .select2-selection__arrow {
            position: absolute;
            top: 7px;
            right: 8px;
            height: 26px;
        }
        .select2-selection__rendered {
            padding-left: 0!important;
            color: #364c69!important;
        }
    }
    .select2-dropdown {
        margin-top: -1px;
        padding: 20px 20px 12px;
        border: 1px solid #e5e5e5;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .1);
        .select2-search {
            margin-bottom: 12px;
            padding: 0;
            .select2-search__field {
                font-family: inherit;
                font-size: 15px;
                font-weight: 400;
                height: 40px;
                padding: 6px 15px;
                color: rgba(54,76,105,.6);
                border: 0;
                border-radius: 2px;
                background-color: #eff1f3;
            }
        }
        .select2-results {
            .select2-results__options {
                max-height: 240px;
                .select2-results__option {
                    font: 400 15px/20px Rubik;
                    padding: 10px 4px;
                    color: rgba(54,76,105,.9);
                    &.select2-results__option--highlighted {
                        color: #7236fd;
                        background-color: transparent;
                    }
                    &[aria-selected=true] {
                        font-weight: bold;
                        color: #4a5d78;
                        background-color: transparent;
                    }
                }
            }
        }
    }
}


.wpcf7-form {
    p {
        margin: 0 0 20px 0;
    }
    label {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        display: block;
        width: 100%;
        margin: 0;
        letter-spacing: -.09px;
        color: rgba(54, 76, 105, .7);
        .required {
            padding-right: 5px;
            color: #7235fd;
        }
    }
    button,
    input,
    select,
    textarea {
        font-weight: 400;
        display: block;
        width: 100% !important;
        max-width: 400px;
        min-height: 40px;
        margin-top: 5px !important;
        padding: 5px 10px;
        border: solid 1px rgba(54, 76, 105, .1) !important;
        border-radius: 2px;
        background-color: #fff;

        appearance: none;
    }
    input[type=submit] {
        width: auto !important;
    }
}

div.wpcf7-response-output {
    margin: 0;
    padding: 10px;
    border-width: 1px;
    border-radius: 2px;
}
