.main {
  padding: 16px 8px;
  background-color: var(--store-background-color);
}

.productItem {
  display: block;
  width: 100%;
}

a.productItem:link:hover {
  text-decoration: none;
  background: white;
  box-shadow: 0 12px 23px 0 rgb(97 75 146 / 3%), 0 2px 17px 0 rgb(0 0 0 / 4%);
}

.productImage {
  display: block;
  width: 100%;
  min-height: 141px;
  padding: 24px 24px 6px;
}

.productDetails {
  padding: 8px 24px 24px;
}

.productList {
  padding-left: 0;
  width: 100%;
  margin: 0 auto;
}

.categoriesPanel {
  display: none;
}

.productTitle {
  color: var(--product-text-color);
  font-weight: bold;
  margin-bottom: 6px
}

.productPrice {
  color: var(--product-price-color);
}

.productRating {
  margin-bottom: 8px;
}

.regularPrice {
  text-decoration: line-through;
  color: #b3bcc7;
}

.filterTitle {
  font-weight: bold;
  font-size: 16px;
  margin: 8px 0;
}

.subCategory {
  display: flex;
}

.subCategory input {
  display: block;
  margin-right: 8px;
}

.subCategory label {
  display: block;
  font-weight: unset;
  user-select: none;
}

.sidebarElement {
  display: none;
}

.sidebarElement.sidebarElementShowing {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 90002;
  & > div:first-child {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fafafa;
    padding: 68px 8px 8px;
    z-index: 10;
    max-width: 270px;
    height: 100vh;
    overflow-y: scroll;
  }
}

@media (min-width: 768px) {
  .productList {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px;
  }
}

@media (min-width: 993px) {
  .main {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 272px 1fr;
  }

  .sidebarElement, .sidebarElement.sidebarElementShowing {
    display: block;
    position: relative;
    padding: 0;
    background-color: unset;
    z-index: unset;
    max-width: unset;
    height: unset;
    overflow-y: unset
  }

  .toggleFiltersButton {
    display: none;
  }

  .categoriesPanel {
    display: block;
    overflow-y: scroll;
  }

  .productList {
    align-items: flex-start;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1148px) {
  .productList {
    max-width: 1280px;
    grid-template-columns: repeat(5, 1fr);
  }
}