.pager {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.numberBlocks {
  width: 400px;
}

.gap, .number, .nextPrev {
  font-size: 14px;
  color: #666;
  background-color: white;
  width: 40px;
  height: 40px;
  border-style: none;
  border: 1px solid #e1e1e1;
  margin: 1px;
}

.nextPrev {
  width: 60px;
}

.number:hover:not(:disabled), .nextPrev:hover {
  background-color: #7235fd;
  opacity: 0.8;
  color: white;
}

.pager button.number:disabled {
  background-color: #7235fd;
  color: white;
  font-weight: bold;
}
