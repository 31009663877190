.root {
  overflow: hidden;
  position: relative;
  height: 1em;
  line-height: 1;
  font-size: 1em;
  width: 5.4em;
  font-family: star;

  font-size: 18px!important;
  width: 94px!important;
  margin-top: 2px!important;
  color: #ffaa5c;
}

.root::before {
  content: "\73\73\73\73\73";
  color: #d3ced2;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
}

.root span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em;
}

.root span::before {
    content: "\53\53\53\53\53";
    top: 0;
    position: absolute;
    left: 0;
}