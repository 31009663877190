.default-footer__root li {
  list-style-type: none;
}

.default-footer__root a:link,
.default-footer__root a:visited {
  color: #f7f7f9;
  text-decoration: none;
}

.default-footer__root a:link:hover,
.default-footer__root a:visited:hover {
  opacity: 0.75;
}
.footer-action-title {
  font-size: 18px;
  line-height: 1.5;
  text-align: left;
  color: #fff;
  margin: 0 0 7px 0;
}

@media (max-width: 992px) {
  .footer-action-title {
    padding-bottom: 5px;
  }
}

.default-footer__root {
  padding-top: 50px;
  padding-bottom: 20px;
}
@media (min-width: 992px) {
  .default-footer__logo-container {
    padding-bottom: 30px;
  }
}
@media (min-width: 1200px) {
  .default-footer__logo-container {
    padding-bottom: 30px;
  }
}

.logged-in .footer-promo-row-wrapper {
  display: none;
}

.footer-promo-row-wrapper {
  display: flex;
  justify-content: center;
  background-color: #364c69;
}

.is-localhost .footer-promo-row-wrapper {
  display: flex;
}

#footer-promo-row {
  background-image: linear-gradient(to right, #ac74fe, #689cf2);
  background-color: #ac74fe;
  max-width: 860px;
  width: 100%;
  padding: 32px;
  margin: 24px 24px 0;
  overflow: hidden;
  border-radius: 12px;
}

.footer-promo-image {
  text-align: center;
}

.footer-promo-image img {
  border-radius: 10px;
  width: 185px;
  height: 185px;
  margin-bottom: 32px;
}

.footer-promo-logo {
  position: absolute;
  top: 17px;
  right: 5%;
  width: 210px;
  height: 311px;
  z-index: -1;
}

.footer-action-form .form {
  padding-bottom: 0px;
}

.footer-action-form {
  position: relative;
  z-index: 1;
}

.footer-action-form input[name="email"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  color: #fff;
  padding: 7px 20px;

  font-size: 16px;
  font-weight: 500;
  line-height: 2.63;
  text-align: left;
  min-width: 240px;
  height: 56px;
  width: 100%;
}

.footer-action-form input[name="email"]::-webkit-input-placeholder {
  opacity: 0.2;
  color: #fff;
}

.footer-action-form input[name="email"]:-moz-placeholder {
  opacity: 0.2;
  color: #fff;
}

.footer-action-form input[name="email"]::-moz-placeholder {
  opacity: 0.2;
  color: #fff;
}

.footer-action-form input[name="email"]:-ms-input-placeholder {
  opacity: 0.2;
  color: #fff;
}

.footer-action-form input[type="submit"] {
  height: 56px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 2px;
  background: #ffaa5c;
  -webkit-box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.21),
    0 2px 13px 0 rgba(255, 170, 92, 0.47);
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.21),
    0 2px 13px 0 rgba(255, 170, 92, 0.47);
  font-size: 16px;
  font-weight: 500;
  line-height: 2.63;
  text-align: center;
  color: #ffffff;
  padding: 7px 20px;
  width: 100%;
  margin: 10px 0;
}

.footer-action-form input[type="submit"]:hover {
  opacity: 0.75;
}

.footer-action-form input[type="submit"]:focus {
  opacity: 0.75;
}

@media (min-width: 641px) {
  #footer-promo-row {
    display: grid;
    grid-template-columns: 185px 1fr;
    column-gap: 32px;
  }

  .footer-promo-image img {
    margin-bottom: 0;
  }

  .footer-action-title {
    font-size: 22px;
  }

  .footer-action-form input[type="submit"] {
    max-width: 192px;
  }
}

.footer-menu-links {
  margin-bottom: 60px;
}

.footer-menu-links li {
  margin: 0 0 10px 0;
}

.footer-menu-links-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -0.1px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 20px;
}
body .footer-menu-links li {
  margin: 0 0 7px 0;
}

.hemi_online_course .hs-product-section > .fg-container {
  width: 100%;
  padding: 0;
}