/* Header related */
body {
    overflow-x: hidden
}

body .back-to-top-theme {
    z-index: 1022
}

.admin-bar header {
    height: 101px
}

.admin-bar header nav {
    height: 68px
}

header.ark-header .navbar-logo {
    line-height: 0 !important
}

header.ark-header .navbar-logo .navbar-logo-wrap {
    line-height: 1px;
    padding-top: 13.5px;
    padding-bottom: 13.5px
}

header.ark-header .navbar-logo .navbar-logo-wrap img {
    height: 41px !important;
    max-height: none !important
}

header.ark-header .ffb-header-template-item-vcenter {
    height: 68px !important
}

header.ark-header .navbar-actions .navbar-actions-shrink {
    line-height: 67px !important
}

.navbar-collapse.collapse.show {
    display: block !important;
    overflow-y: auto !important
}


.navbar.mega-menu .menu-container .navbar-logo .logo-wrap {
    padding-top: 12px !important
}

.navbar.mega-menu .menu-container .navbar-nav {
    margin: 0 0 0 20px;
    transition: all .15s .15s;
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
}

.navbar.mega-menu .menu-container .navbar-nav.navbar-nav-left {
    float: left !important
}

.navbar.mega-menu .menu-container .navbar-nav.search-active {
    transition: all .15s;
    -ms-transform: translate(0, -50px);
    transform: translate(0, -50px)
}

.navbar.mega-menu .menu-container .navbar-nav .menu-item {
    height: 68px;
    padding: 25px 10px 0 10px;
    max-height: 68px;
    overflow: visible;
    line-height: 68px
}

.navbar.mega-menu .menu-container .navbar-nav .menu-item:hover {
    background: 0 0
}

@media (min-width: 992px) {
.navbar.mega-menu .menu-container .navbar-nav .menu-item a {
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    height: 25px;
    padding: 0 2px;
    text-transform: uppercase;
    opacity: .6;
    color: #fff;
    background: 0 0
}
}

.header-shrink .navbar.mega-menu .menu-container .navbar-nav .menu-item a,
.single-product:not(.hemi_online_course) .navbar.mega-menu .menu-container .navbar-nav .menu-item a,
.tax-product_cat .navbar.mega-menu .menu-container .navbar-nav .menu-item a,
.woocommerce-page:not(.archive):not(.hemi_online_course) .navbar.mega-menu .menu-container .navbar-nav .menu-item a {
    color: #364c69
}

.navbar.mega-menu .menu-container .navbar-nav .menu-item a:hover {
    opacity: 1;
    background: 0 0
}

.navbar.mega-menu .menu-container .navbar-nav .menu-item a.disabled {
    opacity: .4
}

@media (min-width: 992px) {
.navbar.mega-menu .menu-container .navbar-nav .menu-item.current-menu-item>a,
.navbar.mega-menu .menu-container .navbar-nav .menu-item.current-page-ancestor>a {
    opacity: 1;
    color: #fff;
    border-bottom: 2px solid #fff;
    border-bottom-width: 2px !important
}
}

.header-shrink .navbar.mega-menu .menu-container .navbar-nav .menu-item.current-menu-item>a,
.header-shrink .navbar.mega-menu .menu-container .navbar-nav .menu-item.current-page-ancestor>a,
.single-product:not(.hemi_online_course) .navbar.mega-menu .menu-container .navbar-nav .menu-item.current-menu-item>a,
.single-product:not(.hemi_online_course) .navbar.mega-menu .menu-container .navbar-nav .menu-item.current-page-ancestor>a,
.tax-product_cat .navbar.mega-menu .menu-container .navbar-nav .menu-item.current-menu-item>a,
.tax-product_cat .navbar.mega-menu .menu-container .navbar-nav .menu-item.current-page-ancestor>a,
.woocommerce-page:not(.archive):not(.hemi_online_course) .navbar.mega-menu .menu-container .navbar-nav .menu-item.current-menu-item>a,
.woocommerce-page:not(.archive):not(.hemi_online_course) .navbar.mega-menu .menu-container .navbar-nav .menu-item.current-page-ancestor>a {
    color: #364c69;
    border-bottom: 2px solid #364c69;
    border-bottom-width: 2px
}

.navbar-actions {
    display: -ms-flexbox;
    display: flex
}

.navbar-actions .template-end-of-header {
    -ms-flex-order: 3;
    order: 3
}

.navbar-actions .shopping-cart-wrapper {
    width: 130px;
    -ms-flex-order: 2;
    order: 2
}

.navbar-actions .shopping-cart-wrapper .shopping-cart>a {
    display: block;
    height: 68px;
    padding-top: 14px
}

.navbar-actions .shopping-cart-wrapper .shopping-cart>a svg * {
    display: block;
    width: 37px;
    height: 34px;
}

@media (min-width: 993px) {
    .navbar-actions .shopping-cart-wrapper .shopping-cart>a svg * {
        fill: #fff;
    }
}

.header-shrink .navbar-actions .shopping-cart-wrapper .shopping-cart>a svg *,
.single-product:not(.hemi_online_course) .navbar-actions .shopping-cart-wrapper .shopping-cart>a svg *,
.tax-product_cat .navbar-actions .shopping-cart-wrapper .shopping-cart>a svg *,
.woocommerce-page:not(.archive):not(.hemi_online_course) .navbar-actions .shopping-cart-wrapper .shopping-cart>a svg * {
    fill: #364c69
}

.navbar-actions .shopping-cart-wrapper .shopping-cart>a .shopping-cart-info {
    float: right;
    padding-left: 10px
}

.navbar-actions .shopping-cart-wrapper .shopping-cart>a .shopping-cart-info span {
    line-height: 20px;
    display: block;
    height: 20px;
}

.shopping-cart-info {
    color: white;
}

.header-shrink .shopping-cart-info {
    color: #9b9b9b;
}

.navbar-actions .shopping-cart-wrapper .shopping-cart>a .shopping-cart-info span.shopping-cart-menu-title {
    font-size: 16px;
    font-weight: 500
}


.navbar-actions .search-classic {
    height: 68px;
    margin: 0 15px 0 0 !important
}

.navbar-actions .search-classic .search-classic-btn {
    position: relative;
    width: 40px;
    height: 40px;
    margin: 14px 0
}

.navbar-actions .search-classic .search-classic-btn:not(.is-clicked) {
    z-index: 99
}

.navbar-actions .search-classic .search-classic-btn:not(.is-clicked)+.search-classic-field .ep-autosuggest {
    display: none !important
}

.navbar-actions .search-classic .search-classic-btn.is-clicked {
    position: fixed;
    z-index: 97;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    cursor: default
}

.navbar-actions .search-classic .search-classic-btn.is-clicked+.search-classic-field {
    width: calc(100vw - 530px);
    transition: width .35s .15s linear;
    height: 40px
}

.navbar-actions .search-classic .search-classic-btn .search-classic-btn-default {
    top: 0;
    width: 40px;
    height: 40px
}

.navbar-actions .search-classic .search-classic-btn .search-classic-btn-default:before {
    display: none
}

.navbar-actions .search-classic .search-classic-btn .search-classic-btn-active {
    display: none
}

.navbar-actions .search-classic .search-classic-field {
    z-index: 98;
    top: 0;
    right: 0;
    display: inline-block !important;
    width: 40px;
    padding: 14px 0;
    transition: width .35s linear;
    opacity: 1 !important;
    border-top: 0;
    background: 0 0
}

.navbar-actions .search-classic .search-classic-field form .input-group {
    border-radius: 4px;
    background: rgba(255, 255, 255, .2);
    display: block;
    width: 100%
}

.header-shrink .navbar-actions .search-classic .search-classic-field form .input-group,
.single-product:not(.hemi_online_course) .navbar-actions .search-classic .search-classic-field form .input-group,
.tax-product_cat .navbar-actions .search-classic .search-classic-field form .input-group,
.woocommerce-page:not(.archive):not(.hemi_online_course) .navbar-actions .search-classic .search-classic-field form .input-group {
    background-color: #eff1f3
}

.navbar-actions .search-classic .search-classic-field form .input-group input {
    font-size: 15px !important;
    font-weight: 400 !important;
    width: calc(100% - 90px);
    padding: 0;
    color: #fff;
    border: 0;
    background: 0 0;
    float: none
}

.navbar-actions .search-classic .search-classic-field form .input-group input+.input-group-btn {
    width: 40px;
    float: right
}

.navbar-actions .search-classic .search-classic-field form .input-group input+.input-group-btn .ie10,
.navbar-actions .search-classic .search-classic-field form .input-group input+.input-group-btn .ie11 {
    position: absolute;
    top: 0;
    left: 0
}

.header-shrink .navbar-actions .search-classic .search-classic-field form .input-group input,
.single-product:not(.hemi_online_course) .navbar-actions .search-classic .search-classic-field form .input-group input,
.tax-product_cat .navbar-actions .search-classic .search-classic-field form .input-group input,
.woocommerce-page:not(.archive):not(.hemi_online_course) .navbar-actions .search-classic .search-classic-field form .input-group input {
    color: #364c69
}

.header-shrink .navbar-actions .search-classic .search-classic-field form .input-group input::-webkit-input-placeholder,
.single-product:not(.hemi_online_course) .navbar-actions .search-classic .search-classic-field form .input-group input::-webkit-input-placeholder,
.tax-product_cat .navbar-actions .search-classic .search-classic-field form .input-group input::-webkit-input-placeholder,
.woocommerce-page:not(.archive):not(.hemi_online_course) .navbar-actions .search-classic .search-classic-field form .input-group input::-webkit-input-placeholder {
    color: #364c69
}

.header-shrink .navbar-actions .search-classic .search-classic-field form .input-group input::-moz-placeholder,
.single-product:not(.hemi_online_course) .navbar-actions .search-classic .search-classic-field form .input-group input::-moz-placeholder,
.tax-product_cat .navbar-actions .search-classic .search-classic-field form .input-group input::-moz-placeholder,
.woocommerce-page:not(.archive):not(.hemi_online_course) .navbar-actions .search-classic .search-classic-field form .input-group input::-moz-placeholder {
    color: #364c69
}

.header-shrink .navbar-actions .search-classic .search-classic-field form .input-group input:-moz-placeholder,
.single-product:not(.hemi_online_course) .navbar-actions .search-classic .search-classic-field form .input-group input:-moz-placeholder,
.tax-product_cat .navbar-actions .search-classic .search-classic-field form .input-group input:-moz-placeholder,
.woocommerce-page:not(.archive):not(.hemi_online_course) .navbar-actions .search-classic .search-classic-field form .input-group input:-moz-placeholder {
    color: #364c69
}

.header-shrink .navbar-actions .search-classic .search-classic-field form .input-group input:-ms-input-placeholder,
.single-product:not(.hemi_online_course) .navbar-actions .search-classic .search-classic-field form .input-group input:-ms-input-placeholder,
.tax-product_cat .navbar-actions .search-classic .search-classic-field form .input-group input:-ms-input-placeholder,
.woocommerce-page:not(.archive):not(.hemi_online_course) .navbar-actions .search-classic .search-classic-field form .input-group input:-ms-input-placeholder {
    color: #364c69
}

.navbar-actions .search-classic .search-classic-field form .input-group input::-webkit-input-placeholder {
    color: #fff
}

.navbar-actions .search-classic .search-classic-field form .input-group input::-moz-placeholder {
    color: #fff
}

.navbar-actions .search-classic .search-classic-field form .input-group input:-moz-placeholder {
    color: #fff
}

.navbar-actions .search-classic .search-classic-field form .input-group input:-ms-input-placeholder {
    color: #fff
}

.navbar-actions .search-classic .search-classic-field form .input-group button {
    width: 40px;
    height: 40px;
    padding: 0;
    border: 0;
    outline: 0;
    background: 0 0;
    background: url(./images/Search_white.svg) no-repeat center center
}

.navbar-actions .search-classic .search-classic-field form .input-group button i {
    display: none
}

.header-shrink .navbar-actions .search-classic .search-classic-field form .input-group button,
.single-product:not(.hemi_online_course) .navbar-actions .search-classic .search-classic-field form .input-group button,
.tax-product_cat .navbar-actions .search-classic .search-classic-field form .input-group button,
.woocommerce-page:not(.archive):not(.hemi_online_course) .navbar-actions .search-classic .search-classic-field form .input-group button {
    background: url(./images/Search_dark.svg) no-repeat center center
}

.navbar-actions .search-classic .search-classic-field form .input-group .ep-autosuggest-container {
    width: calc(100% - 90px);
    display: inline-block
}

.navbar-actions .search-classic .search-classic-field form .input-group .ep-autosuggest-container .search-classic-input {
    width: 100%
}

.navbar-actions .search-classic .search-classic-field form .input-group .ep-autosuggest-container+.input-group-btn {
    width: 40px;
    float: right
}

.navbar-actions .search-classic .search-classic-field form .input-group .ep-autosuggest-container .ep-autosuggest {
    text-align: left;
    margin-left: -25px;
    width: calc(100% + 90px);
    top: calc(100% + 4px);
    border-radius: 4px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .1);
    border: none
}

.navbar-actions .search-classic .search-classic-field form .input-group .ep-autosuggest-container .ep-autosuggest .autosuggest-list {
    padding: 7px 15px
}

.navbar-actions .search-classic .search-classic-field form .input-group .ep-autosuggest-container .ep-autosuggest .autosuggest-list .autosuggest-item>a.autosuggest-link:active,
.navbar-actions .search-classic .search-classic-field form .input-group .ep-autosuggest-container .ep-autosuggest .autosuggest-list .autosuggest-item>a.autosuggest-link:hover,
.navbar-actions .search-classic .search-classic-field form .input-group .ep-autosuggest-container .ep-autosuggest .autosuggest-list .selected {
    background-color: #f2f2f2
}

.welcome-card {
    margin-left: 25px
}

.welcome-card .welcome-msg {
    font-size: 15px;
    color: #fff
}

.header-shrink .welcome-card .welcome-msg,
.single-product:not(.hemi_online_course) .welcome-card .welcome-msg,
.tax-product_cat .welcome-card .welcome-msg,
.woocommerce-page:not(.archive):not(.hemi_online_course) .welcome-card .welcome-msg {
    color: #9b9b9b
}

.welcome-card .welcome-link a {
    font-size: 16px;
    font-weight: 500;
    color: #fff
}

.header-shrink .welcome-card .welcome-link a,
.single-product:not(.hemi_online_course) .welcome-card .welcome-link a,
.tax-product_cat .welcome-card .welcome-link a,
.woocommerce-page:not(.archive):not(.hemi_online_course) .welcome-card .welcome-link a {
    color: #364c69
}

.breadcrumbs-v4-title {
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    color: #fff
}

.archive .navbar-logo-img.navbar-logo-img-fixed,
.single-product .navbar-logo-img.navbar-logo-img-fixed,
.woocommerce-account .navbar-logo-img.navbar-logo-img-fixed,
.woocommerce-cart .navbar-logo-img.navbar-logo-img-fixed,
.woocommerce-checkout .navbar-logo-img.navbar-logo-img-fixed {
    display: inline-block !important
}

@media (max-width:991px) {

    .archive .navbar-logo-img.navbar-logo-img-fixed,
    .single-product .navbar-logo-img.navbar-logo-img-fixed,
    .woocommerce-account .navbar-logo-img.navbar-logo-img-fixed,
    .woocommerce-cart .navbar-logo-img.navbar-logo-img-fixed,
    .woocommerce-checkout .navbar-logo-img.navbar-logo-img-fixed {
        display: none !important
    }
}

.archive .ffb-id-navigation-header .ark-header:not(.header-shrink) .menu-container>ul>li:hover>a.nav-item-child,
.single-product .ffb-id-navigation-header .ark-header:not(.header-shrink) .menu-container>ul>li:hover>a.nav-item-child,
.woocommerce-account .ffb-id-navigation-header .ark-header:not(.header-shrink) .menu-container>ul>li:hover>a.nav-item-child,
.woocommerce-cart .ffb-id-navigation-header .ark-header:not(.header-shrink) .menu-container>ul>li:hover>a.nav-item-child,
.woocommerce-checkout .ffb-id-navigation-header .ark-header:not(.header-shrink) .menu-container>ul>li:hover>a.nav-item-child {
    color: #364c69 !important
}

.archive .ark-header .navbar-logo .navbar-logo-img.navbar-logo-img-normal,
.single-product .ark-header .navbar-logo .navbar-logo-img.navbar-logo-img-normal,
.woocommerce-account .ark-header .navbar-logo .navbar-logo-img.navbar-logo-img-normal,
.woocommerce-cart .ark-header .navbar-logo .navbar-logo-img.navbar-logo-img-normal,
.woocommerce-checkout .ark-header .navbar-logo .navbar-logo-img.navbar-logo-img-normal {
    display: none !important
}

/* Footer-related */
.logo-wrap {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 1px
}

.logo-wrap .logo {
    line-height: 0;
    position: relative;
    z-index: 1;
    margin-top: -30px;
    text-align: center
}

.logo-wrap .logo svg {
    height: 45px;
    fill: #fff
}

.logo-wrap::after,
.logo-wrap::before {
    position: absolute;
    top: 0;
    display: block;
    width: calc(50% - 25px);
    height: 1px;
    content: '';
    background: rgba(0, 0, 0, .4)
}

.logo-wrap::before {
    left: 0
}

.logo-wrap::after {
    right: 0
}

/* Email Modal related */

#emailCaptureModal {
  max-width: 416px;
  height: auto;
  top: auto;
  left: auto;
  right: 93px;
  bottom: 93px;
}

#emailCaptureModal .modal-dialog {
  width: auto;
  margin: 15px;
}

#emailCaptureModal .modal-dialog .modal-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  padding: 56px 30px 4px;
  border: 0;
  border-radius: 0;
  text-align: center;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
}

#emailCaptureModal .modal-dialog .modal-content .close {
  position: absolute;
  top: 16px;
  right: 24px;
  opacity: 0.7;
}

#emailCaptureModal .modal-dialog .modal-content .close:hover {
  opacity: 0.9;
}

#emailCaptureModal .modal-dialog .modal-content img {
  margin-bottom: 16px;
}

#emailCaptureModal .modal-dialog .modal-content p {
  max-width: 100%;
  margin: 0 auto 16px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.06px;
  text-align: center;
  color: #364c69;
}

#emailCaptureModal .modal-dialog .modal-content .btn {
  min-width: 143px;
  font-weight: 100;
  line-height: 28px;
}

#emailCaptureModal .modal-dialog .modal-content a:not(.btn) {
  padding: 16px;
  font-size: 16px;
  cursor: pointer;
}

#emailCaptureModal .modal-dialog .modal-content a:not(.btn):hover {
  text-decoration: none;
}

@media screen and (max-width: 1200px) {
  #emailCaptureModal {
    right: 0;
    bottom: 0;
  }

  #emailCaptureModal .modal-dialog {
    margin: 24px;
  }
}

@media screen and (max-width: 480px) {
  #emailCaptureModal {
    max-width: 100%;
  }
}

@media screen and (max-width: 820px) and (orientation: landscape) {
  #emailCaptureModal {
    max-width: 100%;
    max-height: 100vh;
  }
}

.modal-backdrop.in {
  max-width: 416px;
  height: auto;
  top: auto;
  left: auto;
  right: 108px;
  bottom: 108px;
  background-color: transparent;
}

@media screen and (max-width: 480px) {
  .modal-backdrop.in {
    max-width: 100%;
  }
}
