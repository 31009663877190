.blog-grid .blog-grid-supplemental-category,
.blog-grid .blog-grid-supplemental-category a{
	color:#7236fd
}
.widget_archive li a:hover,
.widget_categories li a:hover,
.widget_pages li a:hover,
.widget_meta li a:hover,
.widget_recent_comments li a:hover,
.widget_recent_entries li a:hover,
.widget_nav_menu li a:hover,
.woocommerce.widget_product_categories li a:hover,
.widget_rss li a:hover{
	color:#7236fd
}
#searchform #searchsubmit{
	color:#7236fd
}
#searchform #searchsubmit:hover{
	background-color:#7236fd;
	border-color:#7236fd
}
.widget_tag_cloud .widget-body a:hover{
	background:#7236fd;
	border-color:#7236fd
}
.news-v3 .news-v3-content .news-v3-subtitle a{
	color:#7236fd
}
.news-v4 .news-v4-divider{
	border-bottom:1px solid #7236fd
}
.blog-grid-supplemental .blog-grid-supplemental-title a{
	color:#7236fd
}
.news-v8:hover .news-v8-img-effect:before{
	background-color:#ffaa5c
}
.news-v8 .news-v8-footer-list .news-v8-footer-list-link:hover,
.fg-text-light.news-v8-footer .news-v8-footer-list-item .ff-meta-item a:hover,
.fg-text-light.news-v9-title a:hover{
	color:#7236fd
}
.news-v8 .news-v8-more .news-v8-more-link{
	background-color:#7236fd
}

.breadcrumbs-v1 a:hover{
	color:#7236fd
}
.breadcrumbs-v1 li.active{
	color:#7236fd
}
.breadcrumbs-v2 a:hover{
	color:#7236fd
}
.breadcrumbs-v2 li.active{
	color:#7236fd
}
.breadcrumbs-v3 a:hover{
	color:#7236fd
}
.breadcrumbs-v3 li.active{
	color:#7236fd
}
.breadcrumbs-v4 a:hover{
	color:#7236fd
}
.breadcrumbs-v5 a:hover{
	color:#7236fd
}
.btn-base-bg-anim{
	background:#7236fd
}
.btn-base-bg-anim:hover,
.btn-base-bg-anim:focus,
.btn-base-bg-anim.focus{
	background:#7236fd
}
.btn-group.btn-white-toggle.open .btn-white-bg:after{
	color:#7236fd
}
.btn-group.btn-white-toggle .dropdown-menu>li>a:hover,
.btn-group.btn-white-toggle .dropdown-menu>li>a:focus{
	background:#7236fd
}
.btn-dropdown-group.open .btn-dropdown-toggle{
	background:#7236fd
}
.btn-dropdown-group:hover .btn-dropdown-toggle{
	background:#7236fd
}
.divider-v1 .divider-v1-element-bg,
.divider-v2 .divider-v2-element-bg,
.divider-v3 .divider-v3-element-bg,
.divider-v3-5 .divider-v3-5-element-bg{
	background:#7236fd
}
.divider-v1 .divider-v1-icon,
.divider-v2 .divider-v2-icon,
.divider-v3 .divider-v3-icon,
.divider-v3-5 .divider-v3-5-icon{
	color:#7236fd
}
.divider-v5 .divider-v5-element{
	color:#7236fd
}
.divider-v5 span.before,
.divider-v5 span.after{
	border-color:#7236fd
}
.lists-base .lists-item-element-brd{
	color:#7236fd;
	border:1px solid #7236fd
}
.paginations-v3-list li>a:hover,
.paginations-v3-list li>span:hover{
	color:#7236fd
}
.paginations-v3-list li>.current,
.paginations-v3-list.page-numbers-single li>span,
.paginations-v3-list li.active>a,
.paginations-v3-list li.active>span{
	background:#7236fd
}
.paginations-v3-list li>.current:hover,
.paginations-v3-list.page-numbers-single li>span:hover,
.paginations-v3-list li.active>a:hover,
.paginations-v3-list li.active>span:hover{
	background:#7236fd
}
.pagers-v2 li>a:hover,
.pagers-v2 li>span:hover{
	background:#7236fd
}
.theme-icons-base{
	color:#7236fd
}
.theme-icons-wrap>a[href]>.theme-icons-white-bg:hover{
	background-color:#7236fd
}
.animate-theme-icons .animate-theme-icons-base-brd{
	color:#7236fd;
	border:1px solid #7236fd
}
.icon-box-v1 .icon-box-v1-header{
	background-color:#7236fd
}
.icon-box-v2 .icon-box-v2-icons{
	color:#7236fd
}
.testimonials-v1 .testimonials-v1-author{
	color:#7236fd
}
.testimonials-v4 .testimonials-v4-author{
	color:#7236fd
}
.footer-link-v6-list .footer-link-v6-list-item .footer-link-v6-list-link:hover{
	color:#7236fd
}
.header ul.navbar-nav.no-ff>li.current-menu-item>a,
.header .navbar-nav .nav-item-child.active{
	color:#7236fd
}
.header ul.navbar-nav.no-ff>li.current-menu-item>a:hover,
.header .navbar-nav .nav-item-child.active:hover{
	color:#7236fd
}
.header ul.navbar-nav.no-ff>li>a:hover,
.header ul.navbar-nav.no-ff>li>a:focus,
.header .navbar-nav .nav-item-child:hover,
.header .navbar-nav .nav-item-child:focus{
	color:#7236fd
}
@media (max-width: 991px) {
	.header .navbar-nav .nav-item-bg .nav-item-child{
		background:#7236fd
	}

}
@media (min-width: 992px) {
	.header .navbar-nav .nav-item:hover .nav-item-child{
		color:#7236fd
	}
	.header .navbar-nav .nav-item-bg .nav-item-child{
		background:#7236fd
	}

}
.header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown.active,
.header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown:hover,
.header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active,
.header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover,
.header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active,
.header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover,
.header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active,
.header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover{
	color:#7236fd
}
.header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown.active:before,
.header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown.active:after,
.header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown:hover:before,
.header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown:hover:after,
.header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active:before,
.header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active:after,
.header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover:before,
.header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover:after,
.header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active:before,
.header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active:after,
.header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover:before,
.header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover:after,
.header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active:before,
.header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active:after,
.header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover:before,
.header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover:after{
	color:#7236fd
}
.header-fullscreen-menu .nav-dropdown-menu .nav-dropdown-menu-link:hover{
	color:#7236fd
}
.header-fullscreen-menu .nav-dropdown-menu .nav-dropdown-menu-child.active{
	color:#7236fd
}
.header-fullscreen-menu .nav-dropdown-menu .nav-dropdown-menu-child:hover{
	color:#7236fd
}
.header-section-scroll ul.header-section-scroll-menu li.active .nav-item-child:hover,
.header-section-scroll ul.header-section-scroll-menu li.active .nav-item-child:focus{
	color:#7236fd
}
.header-section-scroll .header-section-scroll-menu li a:hover{
	color:#7236fd
}
.header-slide .header-slide-nav .header-slide-nav-link:hover{
	color:#7236fd
}
.header-slide-subnav .header-slide-subnav-link.active{
	background:#7236fd
}
.header-slide-subnav .header-slide-subnav-link.active:hover{
	background:#7236fd
}
.header-slide-subnav .header-slide-subnav-link:hover{
	color:#7236fd
}
.header-transparent .navbar-nav .open .nav-item-child:hover{
	color:#7236fd
}
@media (max-width: 991px) {
	.header-transparent .navbar-nav .nav-item-bg{
		border:1px solid #7236fd
	}
	.header-transparent .navbar-nav .nav-item-bg .nav-item-child{
		background:#7236fd
	}

}
@media (min-width: 992px) {
	.header-transparent .navbar-nav .nav-item:hover .nav-item-child{
		color:#7236fd
	}
	.header-transparent .navbar-nav .nav-item-child.active{
		color:#7236fd
	}
	.header-transparent .navbar-nav .nav-item-bg .nav-item-child{
		background:#7236fd
	}
	.header-transparent .navbar-nav .nav-item-bg .nav-item-child:hover{
		background:#7236fd
	}
	.header-transparent .navbar-nav .nav-item-bg:hover .nav-item-child{
		background:#7236fd
	}

}
.search-menu .search-btn .search-btn-default:hover,
.search-menu .search-btn .search-btn-active:hover{
	color:#7236fd
}
.ark-woocommerce-products a.title:hover{
	color:#7236fd
}
.ark-woocommerce-products .add_to_cart_button,
.ark-woocommerce-products .added_to_cart{
	border:1px solid #7236fd;
	color:#7236fd
}
.ark-woocommerce-products .add_to_cart_button:hover,
.ark-woocommerce-products .added_to_cart:hover,
.ark-woocommerce-products .add_to_cart_button.loading{
	background:#7236fd
}
.animsition-loading{
	border-left:2px solid #7236fd
}
.theme-carousel-indicators-v3 li.active,
.theme-carousel-indicators-v3 li.fg-text-dark.active{
	color:#7236fd;
	border-bottom-color:#7236fd
}
.theme-carousel-indicators-v3 li:hover,
.theme-carousel-indicators-v3 li.fg-text-dark:hover{
	border-bottom-color:#7236fd
}
.theme-carousel-indicators-v3 li:hover.active,
.theme-carousel-indicators-v3 li.fg-text-dark:hover.active{
	color:#7236fd;
	border-bottom-color:#7236fd
}
.theme-carousel-indicators-v5 li.active .theme-carousel-indicators-item{
	border:2px solid #7236fd
}
.owl-dot span,
.owl-dots span{
	border:1px solid #7236fd
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-base,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-base{
	background:#7236fd
}
.brd-color-base{
	border-color:#7236fd !important
}
.bg-color-base-hover:hover{
	background:#7236fd !important
}
.icon-color-base-hover:hover .animate-icon-item:last-child{
	color:#7236fd !important
}
.before-bg-base:before{
	background:#7236fd !important
}
.after-bg-base:after{
	background:#7236fd !important
}
.before-after-bg-base:before,
.before-after-bg-base:after{
	background:#7236fd !important
}
.ff-button-base-slide{
	color:#7236fd;
	border-color:#7236fd
}
.ff-button-base-slide:hover,
.ff-button-base-slide:focus,
.ff-button-base-slide.focus{
	background:#7236fd;
	border-color:#7236fd
}
.progress-box-v1 .progress-bar{
	background-color:#7236fd
}
.blog-simple-2-divider{
	background-color:#7236fd
}
.blog-simple-3-divider{
	background-color:#7236fd
}
::selection{
	background:#7236fd
}
::-webkit-selection{
	background:#7236fd
}
::-moz-selection{
	background:#7236fd
}
.blog-classic .blog-classic-content .blog-classic-share .blog-classic-share-item .blog-classic-share-link:hover .blog-share-icon{
	color:#7236fd
}
.blog-classic .blog-classic-content .blog-classic-publisher .blog-classic-publisher-name>a:hover{
	color:#7236fd
}
.blog-grid .blog-grid-supplemental-category{
	color:#7236fd
}
.blog-grid-quote .blog-grid-quote-text:hover{
	color:#7236fd
}
.blog-grid-quote-v2{
	background:#7236fd
}
.blog-grid-thumb .blog-grid-thumb-body:before{
	background:#ffaa5c
}
.featured-article .featured-article-content .featured-article-content-title>a:hover{
	color:#7236fd
}
.blog-sidebar-tags>li>a:hover{
	background:#7236fd;
	border-color:#7236fd
}
.btn-base-brd{
	color:#7236fd;
	border-color:#7236fd
}
.btn-base-brd:hover,
.btn-base-brd:focus,
.btn-base-brd.focus{
	background:#7236fd;
	border-color:#7236fd
}
.btn-white-bg:hover,
.btn-white-bg:focus,
.btn-white-bg.focus{
	background:#7236fd;
	border-color:#7236fd
}
.btn-base-bg-slide{
	background:#7236fd
}
.btn-base-bg-slide:hover,
.btn-base-bg-slide:focus,
.btn-base-bg-slide.focus{
	background:#7236fd
}
.btn-white-bg-slide:after,
.btn-white-bg-slide:before{
	background:#7236fd
}
.news-v1 .news-v1-heading .news-v1-heading-tag:hover{
	color:#7236fd
}
.news-v1 .news-v1-lists .news-v1-lists-item .news-v1-lists-link:hover{
	color:#7236fd
}
.news-v4 .news-v4-title-element-left:after{
	border-bottom-color:#7236fd
}
.news-v4 .news-v4-title-element-right:after{
	border-bottom-color:#7236fd
}
.news-v5 .news-v5-icon{
	color:#7236fd
}
.news-v6 .news-v6-subtitle{
	color:#7236fd
}
.news-v8:hover .news-v8-img-effect:before{
	background:#ffaa5c
}
.news-v8 .news-v8-footer-list .news-v8-footer-list-link:hover{
	color:#7236fd
}
.news-v8 .news-v8-more .news-v8-more-link{
	background:#7236fd
}
.accordion-v1 .panel-title>a[aria-expanded="true"]{
	background:#7236fd
}
.accordion-v1 .panel-title>a:hover{
	background:#7236fd
}
.accordion-v2 .panel-title>a:hover{
	color:#7236fd
}
.accordion-v2 .panel-title>a:hover:after{
	color:#7236fd
}
.accordion-v3 .panel-title>a:hover{
	color:#7236fd
}
.accordion-v3 .panel-title>a:hover:before{
	color:#7236fd
}
.accordion-v4 .panel-title>a:before{
	color:#7236fd
}
.accordion-v4 .panel-title>a:hover{
	color:#7236fd
}
.accordion-v4 .panel-title>a:hover:before{
	color:#7236fd
}
.accordion-v5 .panel-title>a:hover{
	color:#7236fd
}
.accordion-v5 .panel-title>a:hover:after{
	color:#7236fd
}
.breadcrumbs-v1 .breadcrumbs-v1-links>li>a:hover{
	color:#7236fd
}
.breadcrumbs-v1 .breadcrumbs-v1-links>li.active{
	color:#7236fd
}
.breadcrumbs-v2 .breadcrumbs-v2-links>li>a:hover{
	color:#7236fd
}
.breadcrumbs-v2 .breadcrumbs-v2-links>li.active{
	color:#7236fd
}
.breadcrumbs-v3 .breadcrumbs-v3-links>li>a:hover{
	color:#7236fd
}
.breadcrumbs-v3 .breadcrumbs-v3-links>li.active{
	color:#7236fd
}
.btn-base{
	color:#7236fd
}
.btn-base:hover,
.btn-base:focus,
.btn-base.focus{
	background:#7236fd;
	border-color:#7236fd
}
.divider-v1 .divider-v1-element-bg,
.divider-v2 .divider-v2-element-bg,
.divider-v3 .divider-v3-element-bg{
	background:#7236fd
}
.divider-v2 .divider-v2-icon,
.divider-v3 .divider-v3-icon{
	color:#7236fd
}
.lists-base>li>a:hover,
.lists-dark>li>a:hover{
	color:#7236fd
}
.lists-base>li .lists-item-element,
.lists-dark>li .lists-base .lists-item-element,
.lists-base .lists-dark>li .lists-item-element{
	color:#7236fd
}
.lists-base .lists-item-element-brd{
	color:#7236fd;
	border-color:#7236fd
}
.lists-base .lists-item-element-bg{
	background:#7236fd
}
.numeric-base-lists>li>a:hover,
.numeric-dark-lists>li>a:hover{
	color:#7236fd
}
.numeric-base-lists>li{
	color:#7236fd
}
.login .login-form-forgot:hover{
	color:#7236fd
}
.paginations-v1-list>li>a:hover,
.paginations-v1-list>li>span:hover{
	background:#ffaa5c
}
.paginations-v1-list>li.next>a:hover,
.paginations-v1-list>li.next>span:hover,
.paginations-v1-list>li.previous>a:hover,
.paginations-v1-list>li.previous>span:hover{
	color:#7236fd
}
.paginations-v1-list>li.active>a,
.paginations-v1-list>li.active>span{
	background:#7236fd
}
.paginations-v2-list>li>a:hover,
.paginations-v2-list>li>span:hover{
	color:#7236fd;
	border-bottom-color:#7236fd
}
.paginations-v2-list>li.active>a,
.paginations-v2-list>li.active>span{
	color:#7236fd;
	border-bottom-color:#7236fd
}
.paginations-v2-list>li.active>a:hover,
.paginations-v2-list>li.active>span:hover{
	color:#7236fd
}
.paginations-v3-list>li>a,
.paginations-v3-list>li>span{
	color:#7236fd
}
.paginations-v3-list>li>a:hover,
.paginations-v3-list>li>span:hover{
	color:#7236fd
}
.paginations-v3-list>li.active>a,
.paginations-v3-list>li.active>span{
	background:#7236fd;
	border-color:#7236fd
}
.paginations-v3-list>li.active>a:hover,
.paginations-v3-list>li.active>span:hover{
	background:#7236fd
}
.pagers-v1:hover{
	background:#7236fd
}
.pagers-v2 li>a:hover,
.pagers-v2 li>span:hover{
	border-color:#7236fd;
	background:#7236fd
}
.theme-icons-wrap>a[href]>.theme-icons-base-hover:hover{
	background:#7236fd
}
.theme-icons-base-bg{
	background:#7236fd
}
.theme-icons-wrap>a[href]>.theme-icons-grey-light-bg:hover{
	background:#7236fd
}
.theme-icons-base-brd{
	color:#7236fd;
	border-color:#7236fd
}
.theme-icons-wrap>a[href]>.theme-icons-base-brd:hover{
	background:#7236fd;
	border-color:#7236fd
}
.animate-theme-icons .animate-theme-icons-base{
	color:#7236fd
}
.animate-theme-icons .animate-theme-icons-base-bg{
	background:#7236fd
}
.animate-theme-icons .animate-theme-icons-base-brd{
	color:#7236fd;
	border-color:#7236fd
}
.theme-icons-elegant .theme-icons-element .theme-icons-wrap>a[href]>.theme-icons:hover{
	background:#7236fd
}
.image-gallery .image-gallery-overlay{
	background:#ffaa5c
}
.image-gallery-v2 .image-gallery-v2-overlay{
	background:#ffaa5c
}
.footer .footer-address .footer-address-link:hover{
	color:#7236fd
}
.footer .footer-media .footer-media-item .footer-media-link:hover{
	color:#7236fd
}
.footer .footer-tags>li>a:hover{
	background:#7236fd;
	border-color:#7236fd
}
.footer .footer-news-list .footer-news-list-item .footer-news-list-link:hover{
	color:#7236fd
}
.footer .footer-video-title .footer-video-title-link:hover{
	color:#7236fd
}
.footer .footer-copyright .footer-copyright-item>a:hover{
	color:#7236fd
}
.footer .footer-toggle .footer-toggle-link:hover{
	color:#7236fd
}
.footer-v2{
	background:#7236fd
}
.footer-v4 .footer-v4-service .footer-v4-service-item .footer-v4-service-icon{
	color:#7236fd
}
.footer-v4 .footer-v4-service .footer-v4-service-item .footer-v4-service-link:hover{
	color:#7236fd
}
.footer-v4 .footer-v4-news .footer-v4-news-item .footer-v4-news-link:hover{
	color:#7236fd
}
.footer-v4 .footer-v4-link:hover{
	color:#7236fd
}
.footer-v4 .footer-v4-copyright .footer-v4-terms-list .footer-v4-terms-link:hover{
	color:#7236fd
}
.footer-v5 .footer-v5-content-subtitle{
	color:#7236fd
}
.footer-v5 .footer-v5-list .footer-v5-list-item .footer-v5-list-link:hover{
	color:#7236fd
}
.footer-v5-copyright .footer-v5-copright-socials .footer-v5-copright-socials-item .footer-v5-copright-socials-link:hover{
	color:#7236fd
}
.footer-v6 .footer-link-v6-list .footer-link-v6-list-item .footer-link-v6-list-link:hover{
	color:#7236fd
}
.form-modal .form-modal-back-btn-message .form-modal-back-btn-message-link{
	color:#7236fd
}
.form-modal .form-modal-input-group .form-modal-hide-password:hover{
	color:#7236fd
}
.form-modal .form-modal-close-form:hover{
	color:#7236fd
}
.header .navbar-toggle:hover .toggle-icon{
	background:#7236fd
}
.header .navbar-toggle:hover .toggle-icon:before,
.header .navbar-toggle:hover .toggle-icon:after{
	background:#7236fd
}
.header .topbar-toggle-trigger:hover .topbar-toggle-trigger-style{
	background:#7236fd
}
.header .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:before,
.header .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:after{
	background:#7236fd
}
.header .topbar-toggle-trigger-icon-style:hover{
	color:#7236fd
}
.header .navbar-nav .nav-item-child.active{
	color:#7236fd
}
.header .navbar-nav .nav-item-child.active:hover{
	color:#7236fd
}
.header .navbar-nav .nav-item-child:hover,
.header .navbar-nav .nav-item-child:focus{
	color:#7236fd
}
.header .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active{
	color:#7236fd
}
.header .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active:hover{
	color:#7236fd
}
.header .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child:hover{
	background:#7236fd
}
.header .dropdown-menu .dropdown-menu-item.active .dropdown-menu-item-child{
	color:#7236fd
}
.header .dropdown-submenu:hover>.dropdown-submenu-child:not(:hover){
	color:#7236fd
}
.header .dropdown-submenu .dropdown-submenu-child.active{
	color:#7236fd
}
.header .dropdown-submenu .dropdown-submenu-child.active:hover{
	color:#7236fd
}
.header .dropdown-submenu .dropdown-submenu-child.active:hover:after{
	color:#7236fd
}
.header .dropdown-submenu .dropdown-submenu-child:hover{
	background:#7236fd
}
.header .dropdown-submenu.active .dropdown-submenu-child{
	color:#7236fd
}
.header .dropdown-submenu.active .dropdown-submenu-child:hover{
	color:#7236fd
}
@media (max-width: 991px) {
	.header .navbar-nav .nav-item-bg{
		border-color:#7236fd
	}
	.header .navbar-nav .nav-item-bg .nav-item-child{
		background:#7236fd
	}

}
@media (min-width: 992px) {
	.header .navbar-nav .nav-item:hover .nav-item-child{
		color:#7236fd
	}
	.header .navbar-nav .nav-item-bg{
		border-color:#7236fd
	}
	.header .navbar-nav .nav-item-bg .nav-item-child{
		background:#7236fd
	}

}
.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon{
	background:#7236fd
}
.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:before,
.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:after{
	background:#7236fd
}
.header-fullscreen .header-fullscreen-nav-actions-right .nav-item-child{
	background:#7236fd
}
.header-fullscreen .header-fullscreen-nav-bg-overlay .header-fullscreen-nav-close:hover{
	color:#7236fd
}
.header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown.active,
.header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown:hover,
.header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active,
.header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover{
	color:#7236fd
}
.header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown.active:before,
.header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown.active:after,
.header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown:hover:before,
.header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown:hover:after,
.header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active:before,
.header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active:after,
.header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover:before,
.header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover:after,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active:before,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active:after,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover:before,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover:after,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active:before,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active:after,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover:before,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover:after{
	color:#7236fd
}
.header-fullscreen .header-fullscreen-menu .nav-dropdown-menu .nav-dropdown-menu-link:hover{
	color:#7236fd
}
.header-fullscreen .header-fullscreen-menu .nav-dropdown-menu .nav-dropdown-menu-child.active{
	color:#7236fd
}
.header-fullscreen .header-fullscreen-menu .nav-dropdown-menu .nav-dropdown-menu-child:hover{
	color:#7236fd
}
@media (max-width: 991px) {
	.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon{
		background:#7236fd
	}
	.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:before,
	.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:after{
		background:#7236fd
	}

}
@media (min-width: 992px) {
	.header-shrink.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon{
		background:#7236fd
	}
	.header-shrink.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:before,
	.header-shrink.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:after{
		background:#7236fd
	}

}
.header-section-scroll .navbar-toggle:hover .toggle-icon{
	background:#7236fd
}
.header-section-scroll .navbar-toggle:hover .toggle-icon:before,
.header-section-scroll .navbar-toggle:hover .toggle-icon:after{
	background:#7236fd
}
.header-section-scroll .header-section-scroll-menu .nav-item.active .nav-item-child:hover,
.header-section-scroll .header-section-scroll-menu .nav-item.active .nav-item-child:focus{
	color:#7236fd
}
.header-section-scroll .header-section-scroll-menu .nav-item-child:hover{
	color:#7236fd
}
.header-transparent .navbar-toggle:hover .toggle-icon{
	background:#7236fd
}
.header-transparent .navbar-toggle:hover .toggle-icon:before,
.header-transparent .navbar-toggle:hover .toggle-icon:after{
	background:#7236fd
}
.header-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style{
	background:#7236fd
}
.header-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:before,
.header-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:after{
	background:#7236fd
}
.header-transparent .topbar-toggle-trigger-icon-style:hover{
	color:#7236fd
}
.header-transparent .navbar-nav .nav-item-child.active{
	color:#7236fd
}
.header-transparent .navbar-nav .nav-item-child.active:hover{
	color:#7236fd
}
.header-transparent .navbar-nav .nav-item-child:hover{
	color:#7236fd
}
.header-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active{
	color:#7236fd
}
.header-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active:hover{
	color:#7236fd
}
.header-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child:hover{
	background:#7236fd
}
.header-transparent .dropdown-menu .dropdown-menu-item.active .dropdown-menu-item-child{
	color:#7236fd
}
.header-transparent .dropdown-submenu:hover>.dropdown-submenu-child:not(:hover){
	color:#7236fd
}
.header-transparent .dropdown-submenu .dropdown-submenu-child.active{
	color:#7236fd
}
.header-transparent .dropdown-submenu .dropdown-submenu-child.active:hover{
	color:#7236fd
}
.header-transparent .dropdown-submenu .dropdown-submenu-child.active:hover:after{
	color:#7236fd
}
.header-transparent .dropdown-submenu .dropdown-submenu-child:hover{
	background:#7236fd
}
.header-transparent .dropdown-submenu.active .dropdown-submenu-child{
	color:#7236fd
}
.header-transparent .dropdown-submenu.active .dropdown-submenu-child:hover{
	color:#7236fd
}
@media (max-width: 991px) {
	.header-transparent .navbar-nav .nav-item-bg{
		border-color:#7236fd
	}
	.header-transparent .navbar-nav .nav-item-bg .nav-item-child{
		background:#7236fd
	}

}
@media (min-width: 992px) {
	.header-transparent .navbar-nav .nav-item:hover .nav-item-child{
		color:#7236fd
	}
	.header-transparent .navbar-nav .nav-item-child.active{
		color:#7236fd
	}
	.header-transparent .navbar-nav .nav-item-bg{
		border-color:#7236fd
	}
	.header-transparent .navbar-nav .nav-item-bg .nav-item-child{
		background:#7236fd
	}
	.header-transparent .navbar-nav .nav-item-bg .nav-item-child:hover{
		background:#7236fd
	}
	.header-transparent .navbar-nav .nav-item-bg:hover .nav-item-child{
		background:#7236fd
	}

}
.header-vertical .navbar-toggle:hover .toggle-icon{
	background:#7236fd
}
.header-vertical .navbar-toggle:hover .toggle-icon:before,
.header-vertical .navbar-toggle:hover .toggle-icon:after{
	background:#7236fd
}
.header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown.active,
.header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown:hover,
.header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active,
.header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover{
	color:#7236fd
}
.header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown.active:before,
.header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown.active:after,
.header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown:hover:before,
.header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown:hover:after,
.header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active:before,
.header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active:after,
.header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover:before,
.header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover:after,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active:before,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active:after,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover:before,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover:after,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active:before,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active:after,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover:before,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover:after{
	color:#7236fd
}
.header-vertical .header-vertical-menu .nav-dropdown-menu .nav-dropdown-menu-link:hover{
	color:#7236fd
}
.header-vertical .header-vertical-menu .nav-dropdown-menu .nav-dropdown-menu-child.active{
	color:#7236fd
}
.header-vertical .header-vertical-menu .nav-dropdown-menu .nav-dropdown-menu-child:hover{
	color:#7236fd
}
.search .search-btn .search-btn-default:hover,
.search .search-btn .search-btn-active:hover{
	color:#7236fd
}
@media (max-width: 992px) {
	.search.search-white .search-btn .search-btn-default:hover,
	.search.search-white .search-btn .search-btn-active:hover{
		color:#7236fd
	}

}
@media (min-width: 992px) {
	.search.search-white .search-btn .search-btn-default:hover,
	.search.search-white .search-btn .search-btn-active:hover{
		color:#7236fd
	}
	.header-shrink .search.search-white .search-btn .search-btn-default:hover,
	.header-shrink .search.search-white .search-btn .search-btn-active:hover{
		color:#7236fd
	}

}
.search-fullscreen .search-fullscreen-trigger-icon:hover{
	color:#7236fd
}
.search-fullscreen .search-fullscreen-input-group .search-fullscreen-search:hover .search-fullscreen-search-icon{
	color:#7236fd
}
.search-fullscreen .search-fullscreen-bg-overlay .search-fullscreen-close:hover{
	color:#7236fd
}
@media (min-width: 992px) {
	.search-fullscreen.search-fullscreen-trigger-white .search-fullscreen-trigger-icon:hover{
		color:#7236fd
	}
	.header-shrink .search-fullscreen.search-fullscreen-trigger-white .search-fullscreen-trigger-icon:hover{
		color:#7236fd
	}

}
.search-on-header .search-on-header-btn .search-on-header-btn-default:hover,
.search-on-header .search-on-header-btn .search-on-header-btn-active:hover{
	color:#7236fd
}
@media (max-width: 992px) {
	.search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-default:hover,
	.search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-active:hover{
		color:#7236fd
	}

}
@media (min-width: 992px) {
	.search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-default:hover,
	.search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-active:hover{
		color:#7236fd
	}
	.header-shrink .search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-default:hover,
	.header-shrink .search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-active:hover{
		color:#7236fd
	}

}
.sidebar-trigger:hover .sidebar-trigger-icon{
	background:#7236fd
}
.sidebar-trigger:hover .sidebar-trigger-icon:before,
.sidebar-trigger:hover .sidebar-trigger-icon:after{
	background:#7236fd
}
.sidebar-nav .sidebar-nav-trigger:hover .sidebar-trigger-icon:before,
.sidebar-nav .sidebar-nav-trigger:hover .sidebar-trigger-icon:after{
	background:#7236fd
}
.sidebar-nav .sidebar-nav-about-link:hover{
	color:#7236fd
}
@media (min-width: 992px) {
	.sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon{
		background:#7236fd
	}
	.sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon:before,
	.sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon:after{
		background:#7236fd
	}
	.header-shrink .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon{
		background:#7236fd
	}
	.header-shrink .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon:before,
	.header-shrink .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon:after{
		background:#7236fd
	}

}
.topbar-c .topbar-c-contacts .topbar-c-contacts-link:hover{
	color:#7236fd
}
.topbar-c .topbar-c-actions .topbar-c-actions-link:hover{
	color:#7236fd
}
.topbar-c .topbar-c-actions .topbar-c-actions-link-border:hover{
	background:#7236fd;
	border-color:#7236fd
}
.topbar-e .topbar-e-list .topbar-e-list-item .topbar-e-list-dropdown:hover{
	color:#7236fd
}
.topbar-e .topbar-e-actions .topbar-e-actions-link:hover{
	color:#7236fd
}
.topbar-e .topbar-e-sh-cart .topbar-e-sh-cart-link:hover{
	color:#7236fd
}
.topbar-e .topbar-e-sh-cart .topbar-e-sh-cart-menu-product-name:hover{
	color:#7236fd
}
.topbar-e .topbar-e-sh-cart .topbar-e-sh-cart-close:hover{
	color:#7236fd
}
.topbar-e .topbar-e-sh-cart .topbar-e-sh-cart-subtotal-price{
	color:#7236fd
}
.topbar-e .topbar-e-sh-cart .topbar-e-sh-cart-subtotal-view-link:hover{
	color:#7236fd
}
.topbar-t .topbar-t-list .topbar-t-list-item .topbar-t-list-dropdown:hover{
	color:#7236fd
}
.topbar-t .topbar-t-list .topbar-t-list-dropdown-menu .topbar-t-list-dropdown-menu-link:hover{
	background:#7236fd
}
.topbar-t .topbar-t-sh-cart .topbar-t-sh-cart-link:hover{
	color:#7236fd
}
.topbar-t .topbar-t-sh-cart .topbar-t-sh-cart-menu-product-name:hover{
	color:#7236fd
}
.topbar-t .topbar-t-sh-cart .topbar-t-sh-cart-close:hover{
	color:#7236fd
}
.topbar-t .topbar-t-sh-cart .topbar-t-sh-cart-subtotal-price{
	color:#7236fd
}
.topbar-t .topbar-t-sh-cart .topbar-t-sh-cart-subtotal-view-link:hover{
	color:#7236fd
}
.contact-us .contact-us-timeline-day{
	background:#7236fd
}
.faq .faq-search .faq-search-search:hover .faq-search-search-icon{
	color:#7236fd
}
.animsition-loading{
	border-left-color:#7236fd
}
.back-to-top-theme{
	background:#7236fd
}
.back-to-top-theme:hover{
	background:#7236fd
}
.owl-dot span,
.owl-dots span{
	border-color:#7236fd
}
.owl-dot.active span,
.owl-dots.active span{
	background:#7236fd
}
.testimonials-v4-arrows .arrow-next:hover,
.testimonials-v4-arrows .arrow-prev:hover{
	background:#7236fd
}
.owl-control-arrows-v1 .owl-arrow:hover{
	background:#7236fd
}
.theme-carousel-control-v2 .carousel-control-arrows-v2:hover{
	background:#7236fd
}
.theme-carousel-indicators-v2 li.active{
	background:#7236fd;
	border-color:#7236fd
}
.theme-carousel-indicators-v3 li.active{
	color:#7236fd;
	border-bottom-color:#7236fd
}
.theme-carousel-indicators-v3 li:hover{
	border-bottom-color:#7236fd
}
.theme-carousel-indicators-v3 li:hover.active{
	color:#7236fd;
	border-bottom-color:#7236fd
}
.theme-carousel-indicators-v4 li.active{
	background:#7236fd;
	border-color:#7236fd
}
.theme-carousel-indicators-v5 li.active .theme-carousel-indicators-item{
	border-color:#7236fd
}
.tp-loader.spinner2{
	background:#7236fd
}
.checkbox input[type="checkbox"]:checked + label:before,
.checkbox input[type="radio"]:checked + label:before{
	background:#7236fd;
	border-color:#7236fd
}
.shopify-product:hover .shopify-product-name{
	color:#7236fd
}
.language-v1 .language-v1-toggle:hover{
	color:#7236fd
}
.language-v1 .language-v1-dropdown-link:hover{
	background:#7236fd
}
@media (min-width: 992px) {
	.language-v1.language-v1-style-white .language-v1-toggle:hover{
		color:#7236fd
	}
	.header-shrink .language-v1.language-v1-style-white .language-v1-toggle:hover{
		color:#7236fd
	}

}
.news-v11 .news-v11-subtitle a{
	color:#7236fd
}
.image-comparison-container .image-comparison-handle{
	background:#7236fd
}
.testimonials-v2-arrows .arrow-next:hover,
.testimonials-v2-arrows .arrow-prev:hover{
	background:#7236fd
}
.color-base{
	color:#7236fd !important
}
.bg-color-base{
	background:#7236fd !important
}
.accordion-v3 .panel-title>a.fg-text-dark:hover:before,
.accordion-v3 .panel-title>a.fg-text-light:hover:before,
.accordion-v3 .panel-title>a.fg-text-dark:hover,
.accordion-v3 .panel-title>a.fg-text-light:hover,
.accordion-v4 .panel-title>a.fg-text-dark:hover:before,
.accordion-v4 .panel-title>a.fg-text-light:hover:before,
.accordion-v4 .panel-title>a.fg-text-dark:hover,
.accordion-v4 .panel-title>a.fg-text-light:hover{
	color:#7236fd
}
.accordion-v2 .panel-title>a[aria-expanded="true"].fg-text-light,
.accordion-v2 .panel-title>a.fg-text-light:hover,
.accordion-v5 .panel-title>a[aria-expanded="true"].fg-text-light,
.accordion-v5 .panel-title>a.fg-text-light:hover{
	background-color:#7236fd
}
.news-v1 .ff-meta-item.fg-text-dark a:hover{
	color:#7236fd
}
.post-content input[type=button],
.post-content input[type=reset],
.post-content input[type=submit]{
	background-color:#7236fd
}
.shopping-cart-icon-wrapper:hover{
	color:#7236fd
}
.shopping-cart-icon-wrapper:after{
	background:#7236fd
}
.ark-header.ark-cart-classic .shopping-cart .shopping-cart-menu{
	border-top-width:2px;
	border-top-style: solid;
	border-top-color:#7236fd
}
.woocommerce-message{
	border-top-color:#7236fd
}
.woocommerce-message:before{
	color:#7236fd
}
.woocommerce span.onsale{
	background-color:#7236fd
}
.woocommerce div.product p.price,
.woocommerce div.product span.price{
	color:#7236fd
}
.woocommerce ul.products li.product .price{
	color:#7236fd
}
.woocommerce-product-search #searchsubmit{
	color:#7236fd
}
.woocommerce-product-search:hover #searchsubmit{
	background-color:#7236fd;
	border-color:#7236fd
}
.widget_product_tag_cloud .widget-body a:hover{
	background:#7236fd;
	border-color:#7236fd
}
.woocommerce-mini-cart-wrapper a.remove:not(:hover){
	color:#7236fd!important
}
.woocommerce-mini-cart-wrapper a.remove:hover{
	background-color:#7236fd
}
.woocommerce-mini-cart-wrapper .shopping-cart-subtotal-checkout-link{
	background-color:#7236fd
}
.woocommerce-mini-cart-wrapper .shopping-cart-subtotal-checkout-link:hover{
	background-color:#7236fd
}
.woocommerce-mini-cart-wrapper .shopping-cart-subtotal-view-link:hover{
	color:#7236fd
}
.woocommerce-mini-cart-wrapper .shopping-cart-menu-product-name:hover{
	color:#7236fd
}
.woocommerce-mini-cart-wrapper .shopping-cart-subtotal-price{
	color:#7236fd
}
body .woocommerce #respond input#submit.alt,body .woocommerce a.button.alt,body .woocommerce button.button.alt,body .woocommerce input.button.alt{
	background-color:#7236fd
}
body .woocommerce #respond input#submit.alt:hover,body .woocommerce a.button.alt:hover,body .woocommerce button.button.alt:hover,body .woocommerce input.button.alt:hover{
	background-color:#ffaa5c
}
.woocommerce a.button.add_to_cart_button{
	background-color:#7236fd
}
.woocommerce a.button.add_to_cart_button:hover{
	background-color:#7236fd
}
.woocommerce button.button.alt.single_add_to_cart_button{
	background-color:#7236fd
}
.woocommerce button.button.alt.single_add_to_cart_button:hover{
	background-color:#7236fd
}
.post-content.ff-richtext li a{
	color:#7236fd
}