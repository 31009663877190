.single-post-breadcrumbs.breadcrumbs-v5 {
  padding: 0;
}

.search-results {
  background-color: #f7f8fa;

  article {
    background-color: white;
    margin-bottom: 24px;
    padding: 16px;
  }

  article > a:first-child {
    width: 100%;
    display: block;
    margin-bottom: 16px;
  }

  article img {
    width: 100%;
  }

  article h2 a:link:hover,
  article h2 a:visited,
  article img:hover {
    color: rgb(52, 52, 60);
    opacity: 0.75;
  }

  @media (min-width: 640px) {
    article {
      display: flex;
    }

    article > a:first-child {
      width: 200px;
      margin-right: 16px;
    }

    article > a:first-child img {
      width: 200px;
    }
  }

  h2 {
    font-size: 24px;
    font-weight: 300;
    font-family: Rubik, Arial, sans-serif;
    color: rgb(52, 52, 60);
  }
}
