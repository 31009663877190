body .carousel-inner h2.fg-heading {
	padding-left: 40px;
	padding-right: 40px;
}
body.woocommerce-page .woocommerce-message .button,
body.woocommerce-page .woocommerce-info .button,
body.woocommerce-page .woocommerce-info .pull-right,
body.woocommerce-page .woocommerce-message .pull-right {
	margin-top: 10px;
	float: none !important;
}
.product-divider {
	display: block;
	width: 100%;
	height: 1px;
	background: none;
	float: left;
}
body .back-to-top-theme {
	z-index: 1000;
}
@media (max-width: 1400px) and (min-width:1200px) {
	.btn.btn-cat {
		font-size: calc(6px + 0.6vw);
	}
}
@media (max-width: 1200px) {
	body .research-card-wrap {
    height: auto;
    min-height: 280px;
    border-radius: 7px !important;
    margin-bottom: 25px;
    margin-right: 0 !important;
    overflow: hidden;
  }
  body .research-card-wrap .bg-one, body .research-card-wrap .bg-two {
    display: none;
	}
	.research-card-wrap.bordered h2 {
    margin-top: 0;
	}
	body .navbar.mega-menu .menu-container .navbar-nav .menu-item {
		padding-left: 5px;
		padding-right: 5px;
	}
	/*Product single page*/
	.mejs-wmp .mejs-controls .mejs-playpause-button {
		left: 48%;
	}
	.single-product .product-image-section .cart .quick_buy_container, .single-product .product-image-section .woocommerce-variation-add-to-cart .quick_buy_container,
	.single-product .product-image-section .cart .btn-secondary, .single-product .product-image-section .woocommerce-variation-add-to-cart .btn-secondary,
	.single-product .product-image-section .cart .quantity, .single-product .product-image-section .woocommerce-variation-add-to-cart .quantity {
		margin: 0 !important;
	}
	.product-image-section.stuck {
		width: 470px;
	}
	/*Store*/
	.flex-me-inbetween {
		padding-right: 45px;
		padding-left: 15px;
	}
	.flex-me-inbetween .item {
		flex-basis: inherit;
	}
	.flex-me-inbetween .item .btn {
		flex-direction: column;
		justify-content: center;
		padding-top: 15px;
		padding-bottom: 15px;
		height: 100%;
	}
	body .btn.btn-cat.expire-action-btn svg {
		margin-right: 0;
		margin-bottom: 10px;
		height: 40px !important;
	}
	.btn.btn-cat span {
		width: auto;
		text-align: center;
	}
	.ff-custom-slider-2.kill-my-top-padding {
		padding-left: 15px;
	}
	.btn.btn-cat-lg .genre-title {
		font-size: 24px !important;
	}
	ul.products.custom-loop li.product .product-wrap {
		padding: 10px;
	}
	/*Store*/
}
@media (max-width: 991px) and (min-width: 768px) {
	body .ark-header .navbar .navbar-toggle {
		margin-top: 20.5px;
		margin-bottom: 8.5px;
	}
}
@media (max-width: 992px) {
	/*Header*/
	header.ark-cart-right-side-xs .shopping-cart-menu {
		display: none;
	}
	.header.navbar-fixed-top, .header.navbar-fixed-top-boxed {
		position: fixed;
	}
	.navbar-actions .shopping-cart-wrapper .shopping-cart > a .shopping-cart-info span,
	.welcome-card .welcome-msg {
		color: #9b9b9b;
	}
	.navbar-actions .shopping-cart-wrapper .shopping-cart > a svg * {
		fill: #364c69;
	}
	.welcome-card .welcome-link a {
		color: #364c69;
	}
	.navbar-actions .search-classic .search-classic-field form .input-group {
		background: #eff1f3;
	}
	.navbar-actions .search-classic .search-classic-field form .input-group button {
		background: url(../img/Search_dark.svg) no-repeat center center;
	}
	.navbar-actions .search-classic .search-classic-field form .input-group input {
		color: #364c69 !important;
	}
	.navbar-actions .search-classic .search-classic-field form .input-group input::-webkit-input-placeholder {
		color: #364c69 !important;
	}
	.navbar-actions .search-classic .search-classic-field form .input-group input::-moz-placeholder {
		color: #364c69 !important;
	}
	.navbar-actions .search-classic .search-classic-field form .input-group input:-moz-placeholder {
		color: #364c69 !important;
	}
	.navbar-actions .search-classic .search-classic-field form .input-group input:-ms-input-placeholder {
		color: #364c69 !important;
	}
	header.ark-header .navbar-logo .navbar-logo-wrap img {
		height: 35px !important;
	}
	.navbar.mega-menu .menu-container .navbar-nav .menu-item a,
	.navbar.mega-menu .menu-container .navbar-nav .menu-item.current-menu-item > a, .navbar.mega-menu .menu-container .navbar-nav .menu-item.current-page-ancestor > a {
		color: #364c69;
	}
	.navbar.mega-menu .menu-container .navbar-nav .menu-item {
		height: 45px;
		padding: 10px;
	}
	.ark-header-mobile-fixed nav .navbar-collapse .menu-container {
		padding-bottom: 0;
	}
	.navbar.mega-menu .menu-container .navbar-nav {
		margin-left: 0;
	}
	.navbar-actions .search-classic .search-classic-btn.is-clicked + .search-classic-field {
		width: calc(100vw - 425px);
		transition: width .35s .15s linear;
		height: 40px;
	}
	.shopping-cart-menu-wrapper {
    position: absolute;
    right: -80px;
	}
	header.ark-cart-right-side-xs .shopping-cart-wrapper.open .shopping-cart-menu {
		display: block;
	}
	body #wpadminbar #wp-admin-bar-new_draft > .ab-item {
		width: 30px;
		overflow: hidden;
	}
	body #wpadminbar #wp-admin-bar-new_draft > .ab-item::before {
		width: 30px;
	}
	body.admin-bar header:not(.woocommerce-Address-title) {
		height: 68px;
		margin-top: 32px;
	}
	body.admin-bar header .shopping-cart-menu {
		top: 0;
	}
	body.admin-bar .navbar.mega-menu .fg-container {
    background-color: #fff;
	}
	body.admin-bar.single-product .ark-header .navbar-toggle {
    margin-top: 23.5px;
    margin-bottom: 16.5px;
	}
	/*Header*/
	/*Footer*/
	.page-footer span.align-right {
		display: block;
		position: relative;
		float: none;
		text-align: center;
		margin-top: 15px;
	}
	/*Footer*/
	/*Homepage*/
	.img-limit-me a {
		text-align: center;
	}
	.img-limit-me a img {
		max-width: 300px;
	}
	body .ffb-id-pfeamtm .ffb-id-1j76res6 {
		padding-bottom: 0;
		padding-top: 40px;
	}
	body ul.products.custom-loop {
		display: block;
		margin-top: 35px;
	}
	body ul.products.custom-loop li.product {
		z-index: 1;
		display: block;
		width: 49% !important;
		margin-right: 1% !important;
		float: left !important;
		min-height: 555px;
	}
	body ul.products.custom-loop li.product .product-wrap {
		border-radius: 3px;
		background-color: #fff;
		box-shadow: 0 12px 23px 0 rgba(97, 75, 146, 0.03), 0 2px 17px 0 rgba(0, 0, 0, 0.04);
		min-height: 555px;
		padding: 30px;
	}
	ul.products.custom-loop li.product a > span:not(.price), ul.products.custom-loop li.product a > span:not(.image-wrap) {
		display: block;
	}
	ul.products.custom-loop li.product .button {
		display: block;
	}
	body ul.products.custom-loop li.product:nth-child(2n+1) {
		clear: both;
	}
	body ul.products.custom-loop li.product:last-child {
		float: none !important;
		margin: auto !important;
	}
	.ffb-id-1dapjlg8 {
		padding-left: 10px;
		padding-right: 10px;
		width: 100% !important;
	}
	/*Homepage*/
	/*Store page*/
	.page-id-60 {
		background-color: #f7f7f7;
	}
	.ff-custom-slider-2 .owl-stage-outer {
		margin-top: 15px;
		margin-bottom: 60px;
	}
	.flex-me-inbetween {
		flex-direction: column;
		padding-right: 15px;
		padding-left: 15px;
	}
	.flex-me-inbetween .item {
		margin-right: 0;
	}
	.flex-me-inbetween .item .btn {
		flex-flow: row wrap;
		justify-content: flex-start;
	}
	body .btn.btn-cat.expire-action-btn svg {
		margin-right: 20px;
		margin-bottom: 0;
	}
	.ffb-id-27aildea.give-me-right-pad {
		padding-right: 0;
	}
	.give-me-right-pad .buttons-el-wrapper {
		text-align: left;
	}
	/*Store page*/
	/*How Hemi Sync works page*/
	.ffb-id-1d0oeena.icon-wrap-svg-bg.section-with-content-icon-wrap {
		top: -50px !important;
	}
	body .section-with-content-icon-wrap {
		position: relative !important;
		margin: auto;
	}
	/*How Hemi Sync works page*/
	/*Research pages*/
	body .research-paper-card {
		min-height: 1px;
	}
	/*Research pages*/
	/*About Us page*/
	body .continuous-innovation-wrap {
    padding: 50px 15px 50px 15px;
	}
	.page-id-11492 .section-with-content-icon-wrap {
		display: none;
	}
	body .section-with-content {
		margin: 50px 0;
	}
	body .section-about-video-link {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	/*About Us page*/
	/*Experience meditation*/
	body .product-action-on-one-exp-wrap-bg {
		min-height: 0;
		max-height: unset !important;
		max-height: auto !important;
		padding-top: 50px;
		padding-bottom: 50px;
	}
	body .product-action-on-one-exp-wrap-bg h2,
	body .product-action-on-one-exp-wrap-bg h2 *,
	body .product-action-on-one-exp-wrap-bg h2:hover {
		font-size: 36px !important;
		letter-spacing: 1px !important;
	}
	body .product-program-on-one-exp-wrap-bg {
		padding-top: 50px;
	}
	/*Experience meditation*/
	/*Experience relaxation*/
	body .ffb-id-1mh5a6ib {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	body .custom-expir-bg {
		min-height: 1px;
		padding-top: 50px;
		padding-bottom: 50px;
	}
	body .custom-expir-child-svg {
		max-width: 400px;
		width: 100%;
		margin-top: 25px;
	}
	/*Experience relaxation*/
	/*See all common button*/
	body .ffb-id-1i7of9nf,
	body .ffb-id-1ind5g2q,
	body .ffb-id-1inep3sq,
	body .ffb-id-1ing2rk6,
	body .ffb-id-1inafdbb,
	body .ffb-id-1ine92rl {
		margin-top: 25px;
		text-align: left;
	}
	/*See all common button*/
	/*Product category*/
	body.tax-product_cat .sidebar-menu {
		padding-left: 10px;
	}
	body .sidebar-menu ul li {
		width: 33.3%;
		display: inline-block;
	}
	body .sidebar-menu ul li a {
		font-size: 12px;
	}
	body.tax-product_cat ul.products.custom-loop li.product:last-child {
		float: left !important;
	}
	body.tax-product_cat ul.products.custom-loop li.product {
		clear: none;
		flex: 49%;
		padding: 0;
		min-height: 0;
	}
	body.tax-product_cat ul.products.custom-loop li.product .product-wrap {
		position: relative;
		min-height: 0;
		height: 100%;
	}
	ul.products.custom-loop li.product.product-category:nth-child(even) {
		margin-left: 0 !important;
	}
	.category-sidebar-menu {
		width: 0;
	}
	.categories-list {
		width: 100%;
	}
	.tax-product_cat .product .play-sample {
		display: block;
	}
	.tax-product_cat ul.products.custom-loop {
		display: flex;
	}
	/*Product category*/
	/*Product single page*/
	.single-product .product-image-section h4 a {
    letter-spacing: 0;
    font-size: 12px;
	}
	.single-product .product-image-section h4 {
    line-height: 1;
	}
	.single-product .woocommerce-product-gallery__wrapper img {
    width: 100%;
    height: auto;
	}
	.single-product .product-image-section .cart, .product-image-section .woocommerce-variation-add-to-cart {
		margin-top: 15px;
		margin-bottom: 15px !important;
	}
	.single-product .variations {
		margin-bottom: 15px !important;
	}
	.single-product .product-image-section .cart .quick_buy_container {
		margin-left: 0;
	}
	.single-product .product-summary-section {
		margin-top: 25px;
	}
	.single-product .quantity input {
		text-align: center !important;
	}
	.product-image-section.product-fixed-part {
		margin-top: 15px;
	}
	.single-product #comments .text-right {
		text-align: left;
		margin-top: 15px;
	}
	.single-product .product-summary-section .product-summary .learn-more {
		text-align: left;
	}
	.single-product .page-footer {
		position: relative;
		z-index: 3;
	}
	.single-product.woocommerce .woocommerce-breadcrumb {
    margin: 20px 0 0 15px;
	}
	.single-product .product-summary-section {
		padding-right: 15px;
	}
	section.related.products ul.products.custom-loop {
		display: flex;
		justify-content: space-between;
	}
	.related.products .custom-loop .product {
		max-width: calc(33.3% - 10px);
		padding: 0;
		min-height: 0;
	}
	.related.products ul.products.custom-loop li.product:last-child {
		margin: 0 0 10px 0 !important;
	}
	.related.products .custom-loop .product .product-wrap {
		position: relative;
		min-height: 0;
		height: 100%;
	}
	.wcmp-player-container .mejs-container .mejs-controls .mejs-horizontal-volume-slider {
		right: 30px;
	}
	.mejs-wmp .mejs-controls .mejs-playpause-button {
		left: unset !important;
		right: 370px;
	}
	.wcmp-player-container .mejs-container .mejs-controls .mejs-volume-button {
		right: 180px;
	}
	.wcmp-player-container .mejs-container .mejs-controls .mejs-time.mejs-duration-container {
		right: 265px;
	}
	.wcmp-player-container .mejs-container .mejs-controls .mejs-time.mejs-currenttime-container {
		right: 305px;
	}
	.single-product .audio-info .product_title {
		text-align: left !important;
	}
	.single-product .my-30 {
		text-align: left;
	}
	#review_form_wrapper #review_form,
	#review_form_wrapper #grouped_form,
	#grouped_form_wrapper #review_form,
	#grouped_form_wrapper #grouped_form {
		max-width: 95%;
	}
	#review_form_wrapper #review_form #respond .group_table .product td input,
	#review_form_wrapper #review_form #grouped .group_table .product td input,
	#review_form_wrapper #grouped_form #respond .group_table .product td input,
	#review_form_wrapper #grouped_form #grouped .group_table .product td input,
	#grouped_form_wrapper #review_form #respond .group_table .product td input,
	#grouped_form_wrapper #review_form #grouped .group_table .product td input,
	#grouped_form_wrapper #grouped_form #respond .group_table .product td input,
	#grouped_form_wrapper #grouped_form #grouped .group_table .product td input {
		height: 35px !important;
	}
	#review_form_wrapper #review_form #respond .group_table .product td.label label,
	#review_form_wrapper #review_form #grouped .group_table .product td.label label,
	#review_form_wrapper #grouped_form #respond .group_table .product td.label label,
	#review_form_wrapper #grouped_form #grouped .group_table .product td.label label,
	#grouped_form_wrapper #review_form #respond .group_table .product td.label label,
	#grouped_form_wrapper #review_form #grouped .group_table .product td.label label,
	#grouped_form_wrapper #grouped_form #respond .group_table .product td.label label,
	#grouped_form_wrapper #grouped_form #grouped .group_table .product td.label label {
		width: 95%;
		font-size: 14px !important;
	}
	#review_form_wrapper #review_form #respond .group_table .product td,
	#review_form_wrapper #review_form #grouped .group_table .product td,
	#review_form_wrapper #grouped_form #respond .group_table .product td,
	#review_form_wrapper #grouped_form #grouped .group_table .product td,
	#grouped_form_wrapper #review_form #respond .group_table .product td,
	#grouped_form_wrapper #review_form #grouped .group_table .product td,
	#grouped_form_wrapper #grouped_form #respond .group_table .product td,
	#grouped_form_wrapper #grouped_form #grouped .group_table .product td {
		width: 50px;
	}
	#review_form_wrapper #review_form #respond .group_table .product td.price,
	#review_form_wrapper #review_form #grouped .group_table .product td.price,
	#review_form_wrapper #grouped_form #respond .group_table .product td.price,
	#review_form_wrapper #grouped_form #grouped .group_table .product td.price,
	#grouped_form_wrapper #review_form #respond .group_table .product td.price,
	#grouped_form_wrapper #review_form #grouped .group_table .product td.price,
	#grouped_form_wrapper #grouped_form #respond .group_table .product td.price,
	#grouped_form_wrapper #grouped_form #grouped .group_table .product td.price {
		width: 50px;
	}
	.single-product .product-image-section span.onsale {
		top: -10px;
		left: -10px;
	}
	.single-product .product-image-section {
		position: relative;
		width: 100%;
		padding-left: 15px;
	}
	.single-product .product-summary-section {
		margin-left: auto;
	}
	/*Product single page*/
	/*Cart page*/
	body.admin-bar.woocommerce-cart header,
	body.admin-bar.woocommerce-checkout header {
		margin-top: 0;
	}
	.woocommerce-page.woocommerce-cart .page-wrapper,
	.woocommerce-page.woocommerce-checkout .page-wrapper {
		padding-top: 0;
	}
	.woocommerce-page.woocommerce-cart .ark-header .navbar-toggle,
	.woocommerce-page.woocommerce-checkout .ark-header .navbar-toggle {
    margin-top: 21.5px;
    margin-bottom: 0;
	}
	.woocommerce table.shop_table tbody tr td.product-name a {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
	}
	.woocommerce table.shop_table tbody tr td {
		vertical-align: text-top;
	}
	.woocommerce-page .woocommerce-message, .woocommerce-page .woocommerce-info {
		line-height: 1.6;
	}
	.woocommerce-checkout table.shop_table tbody tr td.product-name {
		font-size: 14px;
	}
	.woocommerce-checkout table.shop_table tbody tr td.product-name img {
		display: block;
		margin-bottom: 5px;
	}
	/*Cart page*/
	/*Article page*/
	body .vertical-share-wrap.share-all-wrapper .share-title {
		text-align: left;
		padding-left: 15px;
	}
	body .vertical-share-wrap.share-all-wrapper .share-wrapper .addtoany_list > a {
		display: inline-block;
		text-align: left;
		margin-bottom: 0;
	}
	/*Article page*/
	/*Account page*/
	body.admin-bar.woocommerce-account header {
		margin-top: 0;
	}
	.woocommerce-page.woocommerce-account:not(.archive) .page-wrapper {
		padding-top: 0;
	}
	.woocommerce-page.woocommerce-account:not(.archive) .page-wrapper .fg-section:first-child {
		padding-top: 0;
		padding-bottom: 50px;
	}
	.woocommerce-page.woocommerce-account .ark-header .navbar-toggle {
		margin-bottom: 15px;
	}
	.woocommerce-page.woocommerce-account table.shop_table tbody tr td {
		vertical-align: middle;
	}
	.woocommerce-account .woocommerce .woocommerce-MyAccount-content {
    width: 100%;
    padding: 25px 15px;
    border-left: none;
    border-top: 1px solid #e5e5e5;
    margin-top: 25px;
	}
	.woocommerce-account.woocommerce-downloads .woocommerce .woocommerce-MyAccount-content {
		overflow: scroll;
	}
	.woocommerce-account .woocommerce .woocommerce-MyAccount-navigation {
    border-right: none;
	}
	.woocommerce-account .woocommerce .woocommerce-MyAccount-content .woocommerce-error,
	.woocommerce-account .woocommerce .woocommerce-MyAccount-content .woocommerce-message {
		width: auto;
		margin-left: 0;
	}
	.woocommerce-account .auth-form {
		width: 100%;
		margin: 0 auto;
	}
}
@media (max-width: 782px) {
	#wpadminbar .quicklinks li#wp-admin-bar-my-account.with-avatar>a img {
		right: 25px;
	}
}
@media (max-width: 768px) {
	/*Header*/
	body.admin-bar header:not(.woocommerce-Address-title) {
		margin-top: 46px;
	}
	/*Header*/
	/*Help page*/
	body .epkb-search-form-1 button#epkb-search-kb {
		position: relative;
		top: 0;
		left: 0;
		height: 50px;
	}
	/*Help page*/
	/*Hemi-Sync technology*/
	body .ffb-id-1d0p6f7v {
		padding: 0;
	}
	body .ffb-id-1d0oeemb {
		padding-top: 100px;
	}
	/*Hemi-Sync technology*/
	/*Experience meditation page*/
	body .product-action-on-one-exp-wrap-bg h2,
	body .product-action-on-one-exp-wrap-bg h2 *,
	body .product-action-on-one-exp-wrap-bg h2:hover {
		font-size: 26px !important;
		letter-spacing: 0px !important;
		line-height: 1.4 !important;
	}	
	body .base-heading-papers {
		margin-top: 25px;
	}
	body .ffb-id-1i5dc3ki {
		padding-top: 50px;
	}
	body .ffb-id-1i5cohf1 {
		padding-right: 0;
	}
	body .ffb-id-1dv7gcb1 {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	/*Experience meditation page*/
	/*Experience relaxation*/
	body .ffb-id-1i5lef5q {
		padding-right: 0;
	}
	body .album-5-card-wrapper .album-card.card-size-lg h3 {
    font-size: 16px;
	}
	body .album-5-card-wrapper .album-card:nth-child(1) {
		left: 20px !important;
	}
	body .album-5-card-wrapper .album-card:nth-child(2) {
		left: 15px !important;
	}
	body .album-5-card-wrapper .album-card:nth-child(4) {
		right: 15px !important;
	}
	body .album-5-card-wrapper .album-card:nth-child(5) {
		right: 20px !important;
	}
	body .ffb-id-1ind4i9a {
		padding-top: 50px;
	}
	/*Experience relaxation*/
	/*Experience creativity*/
	body .ffb-id-1i7o7khu {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	body .ffb-id-1inepip0 {
		padding-top: 50px;
	}
	body .product-cover-on-one-exp {
		margin-top: 15px;
		margin-bottom: 25px;
	}
	body .ffb-id-1mj5ehpb {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	/*Experience creativity*/
	/*Experience wellness*/
	body .ffb-id-1mj5i5sk {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	body .ffb-id-1mj5lsd3 {
		padding-right: 0;
	}
	body .ffb-id-1i7o7khu {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	body .ffb-id-1ing3b8s {
		padding-top: 50px;
	}
	/*Experience wellness*/
	/*Experience sleep*/
	body .ffb-id-1mj46rgb {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	body .ffb-id-1i7o7khu {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	body .ffb-id-1inab39l {
		padding-top: 50px;
	}
	/*Experience sleep*/
	/*Experience concentration*/
	body .ffb-id-1mj5778s {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	body .ffb-id-1i7o7khu {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	body .ffb-id-1ine9q5k {
		padding-top: 50px;
	}
	/*Experience concentration*/
	/*Product category page*/
	ul.products.custom-loop li.product.product-category:nth-child(even) {
		margin-left: auto !important;
	}
	ul.products.custom-loop li.product.product-category:last-child {
		background-color: #000;
	}	
	.woocommerce-pagination-wrapper ul.page-numbers li a.page-numbers,
	.woocommerce-pagination-wrapper ul.page-numbers li .current {
		width: 36px;
	}
	/*Product category page*/
	/*Product single page*/
	.single-product .product-image-section .cart .btn-secondary, .single-product .product-image-section .woocommerce-variation-add-to-cart .btn-secondary {
		margin-left: 0;
	}
	.single-product .addtoany_list > a {
		margin: 0 3px;
	}
	.single-product .product-summary-section .product-summary h4.artist-title {
		font-size: 16px;
		line-height: 1;
	}
	.wcmp-player-container .mejs-container .mejs-controls .mejs-horizontal-volume-slider {
		right: 15px;
		width: 80px;
		display: none;
	}
	.wcmp-player-container .mejs-container .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
		width: 80px;
		display: none;
	}
	.wcmp-player-container .mejs-container .mejs-controls .mejs-volume-button {
		right: 100px;
		display: none;
	}
	.wcmp-player-container .mejs-container .mejs-controls .mejs-time.mejs-duration-container {
		right: 30px;
	}
	.wcmp-player-container .mejs-container .mejs-controls .mejs-time.mejs-duration-container:after {
		display: none;
	}
	.wcmp-player-container .mejs-container .mejs-controls .mejs-time.mejs-currenttime-container {
		right: 70px;
	}
	.wcmp-player-container .mejs-container .mejs-controls .mejs-playpause-button button {
    width: 30px;
    height: 30px;
    background-size: contain !important;
	}
	.wcmp-player-container .mejs-container .mejs-controls .mejs-playpause-button {
    width: 30px;
    height: 30px;
    margin: 22px 0 0 0;
    right: 130px;
	}
	.single-product .product-summary-section .product-summary.border-top table {
		width: 800px;
	}
	.table-wrapper {
		overflow: scroll;
	}
	#review_form_wrapper #review_form,
	#review_form_wrapper #grouped_form,
	#grouped_form_wrapper #review_form,
	#grouped_form_wrapper #grouped_form {
		padding: 15px;
	}
	#review_form_wrapper #review_form #respond .group_table .product td,
	#review_form_wrapper #review_form #grouped .group_table .product td,
	#review_form_wrapper #grouped_form #respond .group_table .product td,
	#review_form_wrapper #grouped_form #grouped .group_table .product td,
	#grouped_form_wrapper #review_form #respond .group_table .product td,
	#grouped_form_wrapper #review_form #grouped .group_table .product td,
	#grouped_form_wrapper #grouped_form #respond .group_table .product td,
	#grouped_form_wrapper #grouped_form #grouped .group_table .product td {
		width: 35px;
	}
	#review_form_wrapper #review_form #respond .group_table .product td input,
	#review_form_wrapper #review_form #grouped .group_table .product td input,
	#review_form_wrapper #grouped_form #respond .group_table .product td input,
	#review_form_wrapper #grouped_form #grouped .group_table .product td input,
	#grouped_form_wrapper #review_form #respond .group_table .product td input,
	#grouped_form_wrapper #review_form #grouped .group_table .product td input,
	#grouped_form_wrapper #grouped_form #respond .group_table .product td input,
	#grouped_form_wrapper #grouped_form #grouped .group_table .product td input {
		height: 30px !important;
		min-height: 1px;
		font-size: 12px !important;
		padding: 3px !important;
		width: 35px !important;
	}
	#review_form_wrapper #review_form #respond .group_table .product td.label label,
	#review_form_wrapper #review_form #grouped .group_table .product td.label label,
	#review_form_wrapper #grouped_form #respond .group_table .product td.label label,
	#review_form_wrapper #grouped_form #grouped .group_table .product td.label label,
	#grouped_form_wrapper #review_form #respond .group_table .product td.label label,
	#grouped_form_wrapper #review_form #grouped .group_table .product td.label label,
	#grouped_form_wrapper #grouped_form #respond .group_table .product td.label label,
	#grouped_form_wrapper #grouped_form #grouped .group_table .product td.label label {
		width: 85px;
		font-size: 12px !important;
	}
	#review_form_wrapper #review_form #respond .group_table .product td.label,
	#review_form_wrapper #review_form #grouped .group_table .product td.label,
	#review_form_wrapper #grouped_form #respond .group_table .product td.label,
	#review_form_wrapper #grouped_form #grouped .group_table .product td.label,
	#grouped_form_wrapper #review_form #respond .group_table .product td.label,
	#grouped_form_wrapper #review_form #grouped .group_table .product td.label,
	#grouped_form_wrapper #grouped_form #respond .group_table .product td.label,
	#grouped_form_wrapper #grouped_form #grouped .group_table .product td.label {
    width: 100%;
	}
	.single-product .product-image-section span.onsale {
		top: 10px;
		left: 20%;
	}
	/*Product single page*/
	/*Cart*/
	.woocommerce table.shop_table tbody tr td {
		height: initial;
	}	
	.promo-toggle #show-promo + label {
		width: auto;
		float: left;
		background-color: #f1f1f1;
		margin-left: -5px;
	}
	.woocommerce table.shop_table tbody tr td.product-name img {
		margin-right: 0;
	}
	.coupon-place .btn.btn-md {
		padding: 8px 10px;
	}
	.coupon-place .input-text {
		width: 140px;
	}
	.btn.btn-secondary.btn-md[name="update_cart"] {
    float: left;
	}
	.shop_table .actions {
		min-height: 75px;
	}
	.promo-toggle #show-promo:checked ~ .coupon-place {
		z-index: 2;
		box-shadow: 0px 0px 13px 2px #bbb;
	}
	.woocommerce .cart-collaterals .cart_totals .shop_table th, .woocommerce .cart-collaterals .cart_totals .shop_table td {
		width: 100% !important;
	}
	#accordion .panel .panel-collapse#checkout-billing {
		width: 100%;
	}
	#accordion .panel .panel-collapse #order_review {
		overflow: scroll;
	}
	.woocommerce-checkout table.shop_table {
		width: 600px;
	}
	#checkout-shipping .shipping_address, #checkout-shipping .woocommerce-additional-fields__field-wrapper {
		width: 100%;
	}
	#wc-paypal_pro_payflow-cc-form .form-row {
		display: block;
		width: 100%;
	}
	.woocommerce-page:not(.archive) .page-wrapper form #wc-paypal_pro_payflow-cc-form label {
		font-size: 14px !important;
		margin-top: 10px;
	}
	.woocommerce-checkout #accordion .panel .panel-collapse {
		padding-left: 15px;
		padding-right: 15px;
	}
	/*Cart*/
	/*Articles*/
	body .header-for-single-post.header-with-titlebar .breadcrumbs-v4 {
		padding: 100px 0 45px;
	}
	body .vertical-share-wrap.share-all-wrapper .share-wrapper .addtoany_list > a {
		margin-left: 0;
		margin-right: 0;
	}
	/*Articles*/
	/*Account page*/
	.woocommerce-account td.woocommerce-orders-table__cell.woocommerce-orders-table__cell-order-actions .btn-link-icon {
		float: none;
	}
	.woocommerce-page.woocommerce-account table.shop_table tbody tr td {
    vertical-align: middle;
    text-align: left !important;
	}
	.woocommerce-page.woocommerce-account table.shop_table_responsive tr td::before,
	.woocommerce-page.woocommerce-account table.shop_table_responsive tr td::before {
		float: none;
		display: block;
	}
	.woocommerce-page.woocommerce-account .u-column2.col-2.woocommerce-Address {
		margin-top: 35px;
    padding-top: 15px;
    border-top: 1px solid #e5e5e5;
	}
}

@media (max-width: 767px) {
	/*Header*/
	.header-transparent .navbar {
		max-width: 100vw;
	}
	.navbar.mega-menu .fg-container {
		padding-right: 15px !important;
		padding-left: 15px !important;
		margin-left: 0;
	}
	.header-transparent .navbar-logo {
		margin: 0 15px;
	}
	.navbar-actions .shopping-cart-wrapper {
		width: 60px;
	}
	.welcome-card {
		margin-left: 10px;
	}
	.navbar-actions .search-classic .search-classic-btn.is-clicked + .search-classic-field {
		width: calc(100vw - 250px);
	}
	body .ark-header .navbar-toggle {
		margin-bottom: 15px;
		margin-top: 25px;
	}
	/*Header*/
	/*Footer*/
	.page-footer .col-sm-3.col-xs-12 {
		width: 50%;
	}
	/*Footer*/
	/*Homepage*/
	body .section-heading {
		margin-bottom: 40px;
	}
	body ul.products.custom-loop li.product .product-wrap {
		position: relative;
		min-height: 1px;
	}
	body ul.products.custom-loop li.product {
		padding: 0 !important;
		float: none !important;
		margin: 0 auto 15px !important;
		clear: both !important;
		width: 100% !important;
		min-height: 1px !important;
		max-width: 100%;
	}
	body .ff-logo-grid .client-logo {
		width: 50% !important;
		height: 105px !important;
		position: relative;
	}
	.client-logo .clients-v2-content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	body .ff-logo-grid .client-logo .fg-image {
		max-width: 120px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	/*Homepage*/
	/*Store page*/
	body .research-paper-slider .caption-wrap {
		padding-top: 80px;
		padding-right: 15px;
		padding-left: 15px;
	}
	.page-id-60 {
		background-color: #f7f7f7;
	}
	.ff-custom-slider-2 .owl-stage-outer {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 15px;
		padding-bottom: 50px;
	}
	body .ff-custom-slider-2 .btn.btn-cat-lg .genre-title {
		font-size: 20px !important;
	}
	/*Store page*/
	/*Hemi-Sync Technology*/
	.continuous-innovation-wrap.section-with-content {
		text-align: center;
	}
	.continuous-innovation-wrap.section-with-content h2 {
		text-align: center;
	}
	.continuous-innovation-wrap.section-with-content svg {
		max-width: 100%;
	}
	/*Hemi-Sync Technology*/
	/*Research pages*/
	body .research-paper-card .icon-wrap {
		margin: 0 0 30px;
	}
	/*Research pages*/
	/*Product category page*/
	body .sidebar-menu ul li {
		width: 50%;
		display: inline-block;
	}
    body.tax-product_cat ul.products.custom-loop li.product {
        flex: 100%;
    }
	.category-right-wrapper .category-content-head {
		flex-direction: column;
		align-items: flex-start;
		padding-bottom: 35px;
	}
	.category-right-wrapper {
		padding-left: 15px;
		padding-right: 15px;
	}
	/*Product category page*/
	/*Product single page*/
	.single-product .woocommerce-product-gallery__wrapper {
		width: 100%;
		margin: 35px auto;
	}
	.single-product .single-product-image-resp * {
		text-align: center !important;
	}
	.single-product .variations {
		margin-top: 10px !important;
	}
	.header .navbar-logo {
		margin: 0 35px;
	}
	.single-product .cart .btn.btn-lg {
		padding: 8px 10px;
	}
	.single-product .cart .btn.left-icon svg {
		width: 25px;
		margin-right: 10px;
	}
	/*Product single page*/
	.woocommerce-account header.woocommerce-Address-title {
		height: auto;
	}
	.woocommerce-Addresses.col2-set .woocommerce-Address {
		width: auto;
	}
}
@media (max-width: 600px) {
	body.admin-bar header,
	body.admin-bar header:not(.woocommerce-Address-title) {
		margin-top: 46px;
		position: relative !important;
	}
	.header-transparent.navbar-fixed-top, .header-transparent.navbar-fixed-top-boxed {
		position: relative !important;
	}
	.single-product-image-resp {
		padding-right: 0;
	}
	#review_form_wrapper #review_form #respond .group_table .product td.label,
	#review_form_wrapper #review_form #grouped .group_table .product td.label,
	#review_form_wrapper #grouped_form #respond .group_table .product td.label,
	#review_form_wrapper #grouped_form #grouped .group_table .product td.label,
	#grouped_form_wrapper #review_form #respond .group_table .product td.label,
	#grouped_form_wrapper #review_form #grouped .group_table .product td.label,
	#grouped_form_wrapper #grouped_form #respond .group_table .product td.label,
	#grouped_form_wrapper #grouped_form #grouped .group_table .product td.label {
    display: table-cell;
    padding: 0 !important;
    width: 75% !important;
	}
	#review_form_wrapper #review_form #respond .group_table .product td.label label,
	#review_form_wrapper #review_form #grouped .group_table .product td.label label,
	#review_form_wrapper #grouped_form #respond .group_table .product td.label label,
	#review_form_wrapper #grouped_form #grouped .group_table .product td.label label,
	#grouped_form_wrapper #review_form #respond .group_table .product td.label label,
	#grouped_form_wrapper #review_form #grouped .group_table .product td.label label,
	#grouped_form_wrapper #grouped_form #respond .group_table .product td.label label,
	#grouped_form_wrapper #grouped_form #grouped .group_table .product td.label label {
    width: 100%;
    font-size: 12px !important;
    padding: 0 5px !important;
    white-space: normal;
    line-height: 1.4;
	}
	#review_form_wrapper .closemodal, #grouped_form_wrapper .closemodal {
    position: absolute;
    top: 15px;
    right: 15px;
    display: block;
    width: 23px;
    height: 23px;
	}
	#review_form_wrapper .closemodal svg, #grouped_form_wrapper .closemodal svg {
    width: 15px;
	}
	#review_form_wrapper #review_form #respond #reply-title,
	#review_form_wrapper #review_form #grouped #reply-title,
	#review_form_wrapper #grouped_form #respond #reply-title,
	#review_form_wrapper #grouped_form #grouped #reply-title,
	#grouped_form_wrapper #review_form #respond #reply-title,
	#grouped_form_wrapper #review_form #grouped #reply-title,
	#grouped_form_wrapper #grouped_form #respond #reply-title,
	#grouped_form_wrapper #grouped_form #grouped #reply-title {
		font-size: 16px;
		padding-right: 35px;
	}
	.single-product .product-image-section span.onsale {
		top: 10px;
		left: 5px;
	}
	section.related.products ul.products.custom-loop {
		flex-direction: column;
	}
	.related.products .custom-loop .product {
		max-width: 100%;
		min-height: unset !important;
		min-height: auto !important;
	}
	/*Product single page*/
	/*Cart*/
	.woocommerce-page.woocommerce-cart.logged-in .page-wrapper, .woocommerce-page.woocommerce-checkout.logged-in .page-wrapper {
		padding-top: 50px;
	}
	.product-image-section {
		padding-right: 0;
	}
	/*Cart*/
	/*Media player*/
	.wcmp-player-container {
		background-color: #f7f8fa;
	}
	.mejs-container.wcmp-player {
		z-index: 2;
	}
	.wcmp-player-container .audio-info {
		background-color: transparent;
		display: flex;
	}
	.wcmp-player-container .mejs-container .mejs-controls {
		background-color: transparent !important;
	}
	.wcmp-player-container .mejs-container .mejs-controls .mejs-time {
		top: 60px;
	}
	.wcmp-player-container .mejs-container .mejs-controls .mejs-playpause-button {
		margin: 40px 0 0 0;
	}
	/*Media player*/
}

@media (max-width: 640px) {
	body .col-actions-wrap {
		padding-top: 445px;
	}
	/*Footer*/
	/*Homepage*/
	.home .tab-v2 .nav-tabs li .tab-link svg {
		top: 25px;
		max-width: 33px;
	}
	.home .tab-v2 .nav-tabs li .tab-link:before {
		width: 60px;
		height: 60px;
		left: calc(50% - 30px);
	}
	.home .tab-v2 .nav-tabs {
		justify-content: space-between;
	}
	.ffb-id-pfeamtm .ffb-id-1cbiul5r {
		padding: 0 15px;
	}
	.home .tab-v2 .tab-pane .tab-pane-content {
		min-height: 1px;
	}
	.home .tab-v2 .nav-wrapper {
		overflow: scroll;
	}
	.home .tab-v2 .nav-tabs {
		width: 580px;
	}
	.tab-v2 .nav-wrapper::-webkit-scrollbar {
	display: none;
	}
	.home .owl-item .item .testimonials-v1 {
		padding: 0 15px 0 15px;
	}
	/*Homepage*/
	/*Learn page*/
	body .learn-title-block > .fg-link-wrapper-el svg {
		top: -7px;
	}
	/*News page*/
	body .carousel-inner h2.fg-heading {
		font-size: 26px !important;
		margin: 25px auto;
	}
	body .fullwidth-image h2.fg-text-light {
		font-size: 32px;
	}
	body .fullwidth-image {
		min-height: 400px;
		padding: 15px;
	}
	/*Learn page*/
	/*Product single page*/
	.woocommerce-page .woocommerce-message, .woocommerce-page .woocommerce-info {
		max-width: 370px;
		margin: 25px auto;
	}
	.single-product .woocommerce-message, .single-product .woocommerce-info {
		max-width: 330px;
	}
	/*Product single page*/
	/*Music player*/
	.wcmp-player-container {
		height: 110px;
	}
	.wcmp-player-container .mejs-container .mejs-controls .mejs-playpause-button {
		margin-top: 60px;
	}
	.wcmp-player-container .mejs-container .mejs-controls .mejs-time {
		top: 80px;
	}
	/*Search results*/
	.search-result-item-wrap article .ff-post-featured-image-link-wrapper {
		float: none;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 20px;
	}
	.search-result-item-wrap article .ff-post-featured-image-link-wrapper img {
		max-width: 100%;
		max-height: 100%;
	}
	.search-no-results .fg-container-lvl--1 h2 {
		font-size: 36px !important;
	}
}
@media (max-width: 480px) {
	/*Header*/
	.navbar.mega-menu .fg-container {
		padding-left: 0 !important;
	}
	header.ark-header .navbar-actions .navbar-actions-shrink {
		margin-right: 5px !important;
	}
	.navbar-actions .shopping-cart-wrapper {
		width: 40px;
	}
	a.shopping-cart-icon-container.ffb-cart-menu-item svg {
		width: 20px;
	}
	.welcome-card .welcome-msg {
		font-size: 10px;
	}
	.welcome-card .welcome-link a {
		font-size: 14px;
	}
	.navbar-actions .search-classic .search-classic-btn.is-clicked + .search-classic-field {
		width: calc(100vw - 200px);
		transition: width .35s .15s linear;
		height: 40px;
	}	
	/*Header*/
	/*Footer*/
	.page-footer .col-sm-3.col-xs-12 {
		width: 50%;
	}
	/*Footer*/
	/*Homepage*/
	.ffb-id-1dapjlg8 {
		padding: 0;
	}
	/*Homepage*/
	/*Cart*/
	input#giftcard_code {
		width: 190px;
	}
	.ywgc-enter-code {
		width: 100%;
	}
	.woocommerce-cart .back-to-top-theme {
		right: 25px;
	}
	/*Cart*/
	/*Popups*/
	#review_form_wrapper #review_form #respond .comment-form-comment-title {
		width: 100%;
	}
	/*Popups*/
	/*Product single*/
	.single-product .cart .btn.btn-lg {
		font-size: 12px;
		padding: 7px 5px;
	}
	.btn.btn-price .buy_now_price {
		padding-left: 10px;
	}
	.single-product .addtoany_list > a {
		padding: 0 1px;
	}
	.related-header {
		flex-direction: column;
		align-items: flex-start;
	}
}
@media (max-width: 360px) {
	.header-transparent .navbar-logo {
		margin: 6px 15px;
	}
	header.ark-header .navbar-logo .navbar-logo-wrap img {
		height: 24px !important;
	}
}

