.course_banner {
  padding-top: 160px;
  padding-bottom: 40px;
  margin-bottom: 24px;
  background-size: cover;
  text-align: center;

  .main_course_tag {
    display: inline-block;
    text-transform: uppercase;
    color: #444;
    margin: 4px;
    cursor: auto;
  }
  .course_banner__image img {
    border-radius: 10px;
    width: 100%;
    height: auto;
  }
  p {
    color: #485870;
    font-weight: 400;
  }

  .right_product_info {
    margin-top: 12px;

    h2,
    h2 a {
      color: #374b6a;
      font-size: 32px;
      line-height: normal;
      font-weight: 700;
      margin-bottom: 19px;
      text-decoration: none;
      display: block;
    }
  }

  @media (min-width: 521px) {
    padding-top: 80px;
  }

  @media (min-width: 864px) {
    .right_product_info {
      margin-top: 0;
    }
    padding-top: 100px;
    .custom_banner_wraper {
      display: grid;
      grid-template-columns: 512px 1fr;
      column-gap: 20px;
    }
    .course_banner__image img {
      width: 512px;
    }
  }

  .enroll-button {
    text-align: center;
  }

  .enroll_btn {
    background: transparent linear-gradient(98deg, #b470fd 0, #5b9cf8 100%) 0 0
      no-repeat padding-box;
    border-radius: 10px;
    display: inline-block;
    padding: 16px 64px;
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
  }

  .enroll_btn:hover {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
      linear-gradient(285deg, #549ff8, #ba6eff);
    box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
  }
}

.course_banner::after {
  clear: left;
}

.product_section {
  padding-bottom: 40px;

  .price {
    position: absolute;
    top: 25px;
    left: 0;
    height: 34px;
    background: #8475de;
    border-radius: 4px;
    opacity: 1;
    text-align: left;
    padding: 6px 12px;

    .ammount {
      color: #fff;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .products {
    display: grid;
    grid-template-columns: repeat(auto-fill, 372px);
    column-gap: 14px;
    row-gap: 14px;
    justify-content: center;
    padding: 0;

    h4.cate_title {
      font-size: 14px;
      color: #3d53eb;
      font-weight: 400;
      font-family: Lato, sans-serif;
      text-transform: uppercase;
      margin: 15px 0;
    }

    li.product {
      list-style: none;
      position: relative;
      box-shadow: 0 2px 14px #d8d9ffaa;
      padding-bottom: 62px !important;
      border-radius: 12px;

      p {
        margin: 0 0 24px;
        font-size: 16px;
        color: #485870;
        font-weight: 400;
        font-family: Lato, sans-serif;
        margin-top: -16px;
      }

      img {
        height: 280px !important;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100% !important;
        border-radius: 12px 12px 0 0;
      }

      .view_cours_btn {
        border: 2px solid #f1f3f5;
        border-radius: 12px;
        text-decoration: none;
        height: 54px;
        display: block;
        text-align: center;
        font-size: 14px;
        color: #3d53eb;
        font-weight: 700;
        font-family: Lato, sans-serif !important;
        text-transform: uppercase;
        line-height: 52px;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 23px;
        max-width: 284px;
        margin: 0 auto;
      }

      .details_area {
        padding: 0 28px 28px;
      }

      .view_cours_btn:hover {
        color: #fff;
        background: transparent
          linear-gradient(115deg, #6a11cb 0, #b96eff 0, #5e9af8 100%) 0 0
          no-repeat padding-box;
      }

      &:nth-child(3n + 3) {
        margin-right: 0 !important;
      }
    }

    h3.woocommerce-loop-product__title a {
      font-size: 22px;
      color: #29323c;
      font-weight: 400;
      text-decoration: none;
      margin-top: 0;
      margin-bottom: 12px;
      line-height: normal;
      min-height: 102px;
    }
  }

  .pagination {
    padding-top: 24px;
    .page-numbers {
      text-decoration: none;
      width: 39px;
      height: 39px;
      background: #fff 0 0 no-repeat padding-box;
      border: 2px solid #ededed;
      border-radius: 4px;
      text-align: center;
      line-height: 22px;
      display: inline-block;
      margin-right: 6px;
      font-size: 16px;
      font-family: Lato, sans-serif;
      font-weight: 700;
      color: #858585;
      float: none;
    }

    a.next.page-numbers,
    a.prev.page-numbers {
      width: 73px;
    }

    .page-numbers.current,
    .page-numbers:hover {
      color: #fff;
      background: transparent
        linear-gradient(115deg, #6a11cb 0, #b96eff 0, #5e9af8 100%) 0 0
        no-repeat padding-box;
    }

    margin: 0;
    width: 100% !important;
    text-align: center;
  }
}
