.searchApp {
  width: 100%;
  max-width: 1170px;
  input {
    width: 100%;
  }
  margin: auto;

  form {
    padding: 8px;
  }
}

.excerpt {
  font-style: italic;
}

.results {
  .resultItem {
    position: relative;
    padding: 16px;
    background-color: white;
    img {
      width: 100%;
    }
    margin: 24px 0;
  }

  @media (min-width: 640px) {
    .resultItem {
      display: grid;
      grid-template-columns: 200px 1fr;
      column-gap: 16px;
      img {
        width: 200px;
      }
    }
  }
}

.debug {
  position: absolute;
  right: 0;
  top: 0;
  background-color: whitesmoke;
  font-size: 12px;
}