.rootMinimized {
  z-index: 10000000;
  position: fixed;
  background-color: white;
  top: 0;
  right: 0;
  padding: 4px;
  border: 1px solid black;
}

.properties {
  margin: 2px;
  padding: 2px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid brown;
  column-gap: 4px;
}

.root {
  z-index: 10000000;
  position: fixed;
  background-color: white;
  top: 0;
  right: 0;
  padding: 4px;
  border: 1px solid black;
  max-width: 640px;
  width: 100%
}

.root button, .rootMinimized button {
  padding: 0;
  margin: 0;
  font-size: 10px;
  color: black;
  font-weight: bold;
}

.row {
  display: grid;
  grid-template-columns: 50px 1fr 1fr 50px;
  column-gap: 4px;
  border-top: 1px dotted black;
}

.post {
  background-color: whitesmoke;
}

.post span {
  display: inline-block;
  margin: 0 4px;
}