.home_carousel .fg-container {
  padding: 0 4% !important;
}

.home_carousel span.owl-arrow.ff-font-awesome4.icon-angle-left {
  left: -40px;
}

.home_carousel span.owl-arrow.ff-font-awesome4.icon-angle-right {
  right: -40px;
}

.page-id-60 .research-paper-slider .caption-wrap {
  min-height: 520px;
}

.page-template-podcasts-page-php span.breadcrumbs-v5-subtitle {
  display: none;
}

.impprovment:hover {
  background-color: #fff;
  border-color: #fff;
}

.page-id-100796 .post-wrapper .fg-bg-layer.fg-bg-type-color {
  background-color: transparent !important;
}

.page-id-100796 .post-wrapper h2.blog-grid-title-lg {
  display: none;
}

.page-id-100796 .fg-container.container {
  width: 100% !important;
  padding: 0 !important;
}

.page-id-100796 div#post-100796 {
  padding: 0 !important;
}

.page-id-100796 section.ffb-id-pfeamtm {
  padding: 0 !important;
}

.page-id-100796 .category_carousel .container {
  width: 1170px !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.search_bar input::-webkit-input-placeholder {
  color: #555;
  opacity: 1;
}

.search_bar input::-moz-placeholder {
  color: #555;
  opacity: 1;
}

.search_bar input:-ms-input-placeholder {
  color: #555;
  opacity: 1;
}

.small_container {
  max-width: 1160px;
  margin: 0 auto;
}

.color_overlay {
  background-color: #fff;
  position: absolute;
  top: 0;
  height: 617px;
  z-index: 9;
  width: 100%;
  opacity: 0.75;
}

.news_banner {
  padding-top: 140px;
  padding-bottom: 20px;
  background: linear-gradient(#e66465, #9198e5);
  iframe, video {
    width: 100%;
    height: 305px;
  }
  .custom_banner_wraper {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 15px;
    padding: 40px 34px;
    a:hover {
      color: #374b6a !important;
      text-decoration: underline;
    }
    p {
      font-size: 20px !important;
      padding-right: 0;
      font-family: Rubik, sans-serif;
      line-height: 24px !important;
      margin-top: 0;
      color: #485870;
      font-weight: 400;
    }
    h2 {
      color: #374b6a;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 19px;
      text-decoration: none;
      display: block;
    }
    section img, section iframe {
      width: 100%;
      border-radius: 8px;
    }
    @media (min-width: 824px) {
      display: grid;
      grid-template-columns: 420px 1fr;
      column-gap: 16px;
    }
  }
}

.custom_banner_wraper {
  position: relative;
  z-index: 99;
}

ul#caf-layout-pagination li {
  border: 2px solid #ededed;
  border-radius: 4px;
  text-align: center;
  line-height: normal !important;
  display: inline-block !important;
  margin-right: 6px !important;
  font-family: Lato, sans-serif;
  font-weight: 700;
  color: #858585;
  overflow: hidden;
}

ul#caf-layout-pagination li a,
ul#caf-layout-pagination li span {
  width: 39px !important;
  height: 39px !important;
  background: #fff 0 0 no-repeat padding-box !important;
  line-height: 37px !important;
  display: block;
  font-size: 16px !important;
  color: #858585 !important;
  margin: 0 !important;
  padding: 0 !important;
  font-family: Lato, sans-serif !important;
  font-weight: 700;
  background-color: transparent !important;
}

ul#caf-layout-pagination li a.next.page-numbers,
ul#caf-layout-pagination li a.prev.page-numbers {
  width: 70px !important;
}

ul#caf-layout-pagination {
  margin-top: 40px !important;
}

ul#caf-layout-pagination.post-layout1 li span.current,
ul#caf-layout-pagination.post-layout1 li:hover {
  color: #fff !important;
  background: transparent
    linear-gradient(115deg, #6a11cb 0, #b96eff 0, #5e9af8 100%) 0 0 no-repeat
    padding-box !important;
}

ul#caf-layout-pagination.post-layout1 li:hover > a {
  color: #fff !important;
}

.pagination li:hover > a {
  color: #fff;
}

.custom_banner_wraper .right_product_info h3 {
  font-size: 16px;
  color: #5b9bf8;
  text-decoration: none;
  font-weight: 700;
}

.post_section .top_wrapper h3 {
  font-size: 35px;
  color: #a976fd;
  text-transform: capitalize;
  margin-bottom: 17px;
  margin-top: 0;
  font-weight: 700;
}

.post_section .top_wrapper .left_side p {
  font-size: 16px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  color: #29323c;
}

.post_section .top_wrapper .left_side {
  float: left;
  width: 40%;
}

.post_section .search_bar .input_search {
  width: 394px;
  position: relative;
  border: 1px solid #ededed;
  border-radius: 10px;
  height: 40px;
  overflow: hidden;
  margin-top: 14px;
}

.post_section .search_bar input.flexsearch-input {
  height: 100%;
  border: none;
  width: 100%;
  padding: 0 53px 0 17px;
  font-size: 16px;
  color: #909090;
  font-family: Rubik, sans-serif;
  font-weight: 400;
}

.post_section .search_bar .ep-autosuggest-container {
  height: 100%;
}

.post_section .search_bar .submit_btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 47px;
  padding-top: 0;
  text-align: center;
  padding-right: 0;
}

.post_section .search_bar input.flexsearch-submit {
  background-color: transparent !important;
  border: none;
  background-image: url(https://d1es8luhkr7rvi.cloudfront.net/uploads/2021/03/search-icon.png);
  width: 100%;
  height: 100%;
  background-size: 16px;
  background-position: center;
  cursor: pointer;
  border: none !important;
  color: transparent !important;
  background-repeat: no-repeat;
}

.post_section .search_bar button.search_btn {
  display: none;
}

.right_side_fillter {
  float: right;
  width: 54%;
  margin-top: 15px;
}

.post_content .caf-filter-layout ul.caf-filter-container {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  text-align: right;
}

.heading_text li:last-child {
  text-align: right;
}

.heading_text li {
  display: inline-block;
  width: 49%;
}

.heading_text li {
  display: inline-block;
  width: 49%;
}

.heading_text li,
.heading_text li a {
  font-size: 20px;
  color: #a976fd;
  text-decoration: none;
  font-weight: 400;
  text-transform: uppercase;
}

#caf-post-layout-container #caf-filter-layout1 {
  position: absolute;
  top: 113px;
  right: 0;
  max-width: 584px;
}

.caf-filter-layout li.caf-mb-4 {
  list-style: none;
  display: inline-block;
  margin-left: 1.8% !important;
  margin-bottom: 12px !important;
}

.caf-filter-layout li.caf-mb-4:first-child {
  margin-left: 0 !important;
}

.caf-filter-layout .caf-mb-4 a {
  font-size: 14px !important;
  color: #8475de !important;
  text-decoration: none !important;
  border: 1px solid #8475de;
  border-radius: 4px;
  padding: 6px 17px 5px;
  display: block;
  text-align: center;
  font-family: Rubik, Arial, sans-serif !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
  margin: 0;
}

.post_content div#caf-post-layout-container {
  margin-top: 0;
  padding-top: 0;
}

.post_content div#manage-ajax-response {
  margin-top: 0;
  padding: 0;
  min-height: auto;
}

.aside_bar .blog_wrapper {
  background: transparent linear-gradient(180deg, #6199f8 0, #a77bff 100%) 0 0
    no-repeat padding-box;
  box-shadow: 0 2px 14px #d8d9ffaa;
  border-radius: 12px;
  overflow: hidden;
  padding: 28px 12px 17px 12px;
  margin-bottom: 50px;
}

.aside_bar .icon_text {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 27px;
}

.aside_bar .icon_text h5,
.aside_bar .icon_text h5 a {
  font-size: 26px;
  color: #fff;
  display: inline-block;
  margin-left: 12px;
  margin-top: 3px;
  margin-bottom: 0;
  font-weight: 700;
}

.aside_bar .icon_text .icon {
  display: inline-block;
  -ms-transform: translate(0, -6px);
  transform: translate(0, -6px);
}

.aside_bar .icon_text img {
  height: 37px;
  width: 37px;
  margin-left: 5px;
}

.aside_bar .small_blog_row .image_icon {
  background-color: transparent;
  margin-right: 3%;
  width: 26%;
}

.aside_bar .small_blog_row .blog_text {
  width: 63%;
}

.aside_bar .small_blog_row {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.aside_bar .small_blog_row:last-child {
  border-bottom: 0;
}

.aside_bar .small_blog_row .title,
.aside_bar .small_blog_row .title a {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  margin: 5px 0 6px;
  line-height: 20px;
}

.post_content article {
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #fff;
  box-shadow: 0 2px 14px #d8d9ffaa;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 32px !important;
}

.post_section .post_content .news_img img {
  width: 100%;
  display: block;
}

.post_content article .manage-layout1 a {
  display: block;
  width: 33%;
}

.post_content article#caf-post-layout1 .caf-featured-img-box {
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center !important;
}

.post_content article .caf-content-read-more a.caf-read-more:hover {
  background-color: #8475de !important;
  color: #fff !important;
  border-color: #8475de !important;
}

.caf-filter-layout .caf-mb-4 a.active,
.caf-filter-layout .caf-mb-4 a:hover {
  border-color: #8475de !important;
  -ms-transform: scale(1) !important;
  transform: scale(1) !important;
  background-color: #8475de !important;
  color: #fff !important;
}

.post_content article#caf-post-layout1 #manage-post-area {
  background: 0 0 !important;
  width: 63%;
  padding: 17px 24px 17px 24px;
}

.post_section .news_text_info h2,
.post_section .news_text_info h4 a {
  font-size: 25px;
  color: #29323c;
  font-weight: 600;
  text-decoration: none;
  font-family: Rubik, sans-serif;
  line-height: 31px;
}

.post_content article .caf-post-title h2 {
  font-size: 25px !important;
  color: #29323c !important;
  font-weight: 400 !important;
  text-decoration: none !important;
  font-family: Rubik, sans-serif !important;
  line-height: 30px !important;
}

.post_content article .caf-content {
  font-size: 16px !important;
  font-family: Lato, sans-serif !important;
  font-weight: 400 !important;
  color: #29323c !important;
  margin: 0 0 7px !important;
  text-align: left !important;
}

.post_content article .caf-meta-content i {
  color: #29323c !important;
}

.post_content article .caf-meta-content {
  color: #29323c !important;
  display: none !important;
}

.post_content article .manage-layout1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  box-shadow: none !important;
  width: 100%;
}

.post_content article #manage-post-area .caf-post-title > a {
  display: block !important;
  width: 100%;
}

.post_content article #manage-post-area .caf-post-title {
  background-color: transparent !important;
  padding: 0;
}

.post_section span.small_title {
  display: block;
  font-size: 20px;
  color: #29323c;
  font-weight: 400;
  margin-top: 3px;
}

.post_section .pagination {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.post_section .news_text_info p {
  font-size: 16px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  color: #485870;
  margin: 15px 0 26px;
}

.post_content article .caf-content-read-more a.caf-read-more {
  max-width: 119px;
  display: inline-block !important;
  width: 100%;
  text-decoration: none !important;
  border: 1px solid !important;
  text-align: center;
  padding: 3px 0 !important;
  font-size: 15px !important;
  color: #29323c !important;
  border-radius: 4px !important;
  margin-right: 10px !important;
}

.post_section .post_label {
  position: absolute;
  top: 20px;
  left: 0;
  background-color: #6397f8;
  color: #fff;
  text-transform: uppercase;
  max-width: 100px;
  width: 100%;
  text-align: center;
  padding: 5px 0 5px;
}

.post_section .top_wrapper {
  padding-top: 50px;
}

.post_section .small_container {
  position: relative;
}

.aside_bar .blog_wrapper:nth-child(even) {
  background: #fff;
}

.blog_wrapper:nth-child(even) > .icon_text h5 {
  color: #5f96f4;
}

.blog_wrapper:nth-child(even) .image_icon {
  background-color: #a2acba;
}

.blog_wrapper:nth-child(even) .small_blog_row .title a {
  color: #374b6a;
}

.aside_bar .blog_wrapper:nth-child(even) .small_blog_row {
  margin-bottom: 7px;
  padding-bottom: 7px;
}

.aside_bar .small_blog_row .image_icon img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 80px;
}

span.countdown-row.countdown-show4 {
  display: -ms-flexbox;
  display: flex;
  text-align: center;
}

.countdown-period {
  display: block;
}

.countdown-descr {
  display: block;
  width: 100%;
}

#defaultCountdown span.countdown-amount {
  display: block;
  color: #3d53eb;
  font-size: 52px;
  font-weight: 500;
  font-family: "Barlow Semi Condensed", sans-serif;
  margin-bottom: 8px;
  margin-bottom: 0;
}

#defaultCountdown span.countdown-period {
  font-size: 14px;
  color: #29323c;
  font-family: Lato, sans-serif;
  font-weight: 700;
}

#defaultCountdown .countdown-section {
  margin: 0 56px 0 0;
  position: relative;
}

#defaultCountdown .countdown-section:after {
  content: ":";
  position: absolute;
  top: -1px;
  right: -34px;
  color: #fff;
  color: #3d53eb;
  font-size: 52px;
  font-weight: 500;
  font-family: "Barlow Semi Condensed", sans-serif;
}

span.countdown-section:last-child:after {
  display: none;
}

.custom_banner_wraper .price {
  position: absolute;
  top: 36px;
  left: 0;
  height: 34px;
  background: #8475de;
  border-radius: 4px;
  opacity: 1;
  text-align: left;
  padding: 6px 12px;
}

.custom_banner_wraper .price .ammount del {
  margin-top: 0;
  font-weight: 300;
  padding-right: 6px;
}

.custom_banner_wraper .price .ammount {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}

.custom_banner_wraper .impprovment {
  border: 1px solid #8475de;
  border-radius: 4px;
  color: #3d53eb;
  font-size: 14px;
  text-decoration: none;
  font-family: Lato, sans-serif;
  margin-right: 8px;
  padding: 10px 8px;
  font-weight: 700;
  display: inline-block;
}

.custom_banner_wraper .impprovment:hover {
  background-color: #fff;
  border-color: #fff;
}

.custom_banner_wraper .hours li {
  display: inline-block;
  position: relative;
  margin: 0 43px 0 0;
  text-align: center;
  font-size: 14px;
  color: #29323c;
  font-family: Lato, sans-serif;
  font-weight: 700;
}

.custom_banner_wraper .hours strong.time {
  display: block;
  color: #3d53eb;
  font-size: 52px;
  font-weight: 500;
  font-family: "Barlow Semi Condensed", sans-serif;
  margin-bottom: 8px;
}

.custom_banner_wraper .hours ul {
  margin: 0;
  padding: 0;
}

.custom_banner_wraper .hours li:after {
  content: ":";
  position: absolute;
  top: 0;
  right: -28px;
  color: #fff;
  color: #3d53eb;
  font-size: 52px;
  font-weight: 500;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.custom_banner_wraper .enroll_button {
  margin-top: 26px;
}

.custom_banner_wraper .hours li:last-child:after {
  display: none;
}

.four_box.cate-carousel .left_box {
  width: 100% !important;
  margin-right: 0 !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

.category_section .four_box .left_box a {
  font-size: 17px;
  display: -ms-flexbox;
  display: flex;
  height: 80px;
  padding-right: 14px;
  padding-left: 14px;
  letter-spacing: -0.1px;
  color: #364c69;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.18);
  text-decoration: none;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.category_section .cate_icon {
  margin-right: 12px;
}

.cate_icon svg {
  max-height: 40px;
  max-width: 40px !important;
  width: 100% !important;
}

.category_section .four_box .left_box .cate_title {
  width: 68%;
}

.category_section .four_box .left_box h5 {
  margin: 0;
  font-weight: 400;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  color: inherit;
}

.expire-action {
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  position: relative;
}

.category_section .four_box .left_box a:hover {
  transition: all 0.2s;
  color: #fff;
  background-image: linear-gradient(306deg, #549ff8, #ba6eff);
  box-shadow: 0 7px 33px 0, rgba(130, 77, 191, 0.19);
}

.category_section .four_box .left_box a:hover svg * {
  fill: #fff;
}

.needfillcolor {
  fill: #967ffc;
}

.category_section .same_heading h4 {
  text-align: center;
  font-size: 35px;
  color: #a976fd;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 700;
}

.category_section .same_heading p {
  text-align: center;
  color: #29323c;
  font-size: 16px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  line-height: 1.4;
}

.category_section .owl-next,
.category_section .owl-prev {
  background: url(https://static.hemi-sync.cloud/uploads/2021/03/see-all.png)
    no-repeat;
  color: transparent;
  width: 17px;
  height: 27px;
  position: absolute;
  top: 46px;
  cursor: pointer;
}

.category_section .owl-dot > span {
  display: none;
}

.category_section .owl-prev {
  left: -40px;
  -ms-transform: rotate(179deg);
  transform: rotate(179deg);
}

.category_section .owl-next {
  right: -42px;
}

.category_section .slider_wrap {
  padding: 15px 0 0;
}

.category_section .owl-dot {
  width: 12px;
  height: 12px;
  background: #d0d4d9;
  margin-right: 7px;
  border-radius: 80px;
  display: inline-block;
}

.category_section .owl-dots {
  text-align: center;
  margin-top: 0;
}

.category_section .owl-dot.active {
  background-color: transparent;
  border: 1px solid #d0d4d9;
}


.custom_banner_wraper .left_product_img img {
  border-radius: 10px;
  width: 100%;
  height: 490px;
  -o-object-fit: cover;
  object-fit: cover;
}



.four_box.cate-carousel li.item {
  list-style: none;
}

.category_section .slider_wrap ul.owl-carousel {
  padding: 0;
}

a.expire-action[data-term="free"],
a.expire-action[data-term="self-improvement"] {
  display: none !important;
}

.page-template-news
  section.ffb-id-pk7ir8k.fg-section.fg-text-light.has-fg-bg.fg-hover {
  display: none;
}

.category_section .owl-next,
.category_section .owl-prev {
  text-indent: 381px;
}

.wp-next-post-navi-next button,
.wp-next-post-navi-pre button {
  color: #fff !important;
  border: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
    linear-gradient(285deg, #549ff8, #ba6eff);
  box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
  border: none !important;
  font-weight: 400;
  min-height: 55px;
  border-radius: 2px;
  margin-bottom: 45px;
  font-size: 16px !important;
}

.wp-next-post-navi-next button:hover,
.wp-next-post-navi-pre button:hover {
  outline: 0;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
    linear-gradient(285deg, #549ff8, #ba6eff);
  box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
  font-weight: 400;
}

.wp-next-post-navi-next,
.wp-next-post-navi-pre {
  width: 49%;
}

.single-post .header-with-titlebar > .fg-bg > .fg-bg-type-parallax.fg-bg-layer {
  background-image: url(https://static.hemi-sync.cloud/uploads/2021/03/post-single-banner.jpg) !important;
}

.podcast_pagination .page-numbers {
  display: inline-block;
  width: auto !important;
  min-width: 40px;
  height: 100%;
  padding: 7px 10px;
}

.hemi_online_course h1,
.hemi_online_course h2,
.hemi_online_course h3,
.hemi_online_course h4,
.hemi_online_course h5,
.hemi_online_course h6 {
  color: #fff;
}

.course_content .video_section .blue_area.course_fullwidth {
  width: 100%;
}

.course_content .video_section .custom_video {
  background-color: #000;
}

.course_content .video_section li {
  margin-bottom: 0;
  list-style: none;
}

.course_content .video_section li:before {
  content: " ";
  margin-right: 6px;
  display: inline-block;
  position: relative;
  top: -4px;
  width: 8px;
  border-bottom: 2px solid #fff;
}

.course_content .video_section .blue_area ul {
  padding: 0 0 16px 0;
}

.hemi_online_course .course-accordion .panel .panel-title > a:before,
.hemi_online_course .course-accordion .panel:after {
  display: none;
}

.hemi_online_course .course-accordion .panel .panel-title.active > a {
  color: #364c69 !important;
  background-color: rgba(184, 139, 239, 0.1) !important;
}

.hemi_online_course .fg-bg-layer.fg-bg-type-color {
  background: #364c69 !important;
}

@media (min-width: 992px) {
  .genre-links-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
  }
}
